import type { Image, Resource } from '../../../utils/lists';
import type { Theme } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import PhotosDialog from '../PhotosDialog/PhotosDialog';

interface ResourcesDialogProps {
  open: boolean;
  onClose: () => void;
  resources: Resource[] | undefined;
}

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function ResourcesDialog({
  open,
  onClose,
  resources,
}: ResourcesDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState<Image[]>([]);
  const [openPhotosDialog, setOpenPhotosDialog] = useState<boolean>(false);

  const onPhotoClick = (images: Image[]): void => {
    setPhotos(images);
    setOpenPhotosDialog(true);
  };

  return (
    <Dialog open={open} onClose={(): void => onClose()} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography fontWeight="bold" typography="h5" sx={{ pt: 1 }}>
              {t('resourcesdetail_label')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: 'right' }} onClick={(): void => onClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Card
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            borderColor: '#D6E2EB',
            mx: 2,
            mb: 2,
            boxShadow: 3,
            borderRadius: '10px',
          }}
        >
          <TableContainer sx={{ width: '100%', boxShadow: 'none' }}>
            <Table aria-label="defects table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {t('input_type_label')}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resources != null &&
                  resources.length !== 0 &&
                  resources.map((resource: Resource) => (
                    <StyledTableRow
                      key={resource.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {resource.resourceType}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!!(resource.images.length === 0)}
                          onClick={(): void => onPhotoClick(resource.images)}
                        >
                          {resource.images.length > 0 ?
                            <Trans
                              i18nKey="noncompliance_photos_label"
                              values={{ '1': String(resource.images.length) }}
                            /> : t('defectsdetail_photos_label')
                          }
                        </Button>
                      </TableCell>
                      {resource.images.length !== 0 ? (
                        <PhotosDialog
                          open={openPhotosDialog}
                          onClose={(): void => setOpenPhotosDialog(false)}
                          photos={photos}
                        />
                      ) : null}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export default ResourcesDialog;
