import type {
  Context,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';

import {
  createContext,
  useContext,
  useState,
} from 'react';

import { type User, userInitialState } from './User';

interface UserProviderProps {
  children: ReactNode;
}

type UserContextType = [User, Dispatch<SetStateAction<User>>];

const UserContext: Context<UserContextType | null> = createContext<UserContextType | null>(null);

function UserProvider({ children }: UserProviderProps): ReactElement {
  const [userState, setUserState]: [User, Dispatch<SetStateAction<User>>] = useState<User>(userInitialState);

  return (
    <UserContext.Provider value={[userState, setUserState]}>{children}</UserContext.Provider>
  );
}

function useUserContext(): UserContextType {
  const context: UserContextType | null = useContext(UserContext);
  if (context === null) {
    throw new Error('Please wrap this function into the UserProvider');
  }

  return context;
}
export { UserProvider, useUserContext };
