import type { OrderedDepartments, OrderedRoles, OrderedScenarios, UserRole } from '../services/User/User';

const orderRoles = (roles: UserRole[]): OrderedRoles => {
  return { departments: roles.reduce((acc: OrderedDepartments[], role: UserRole) => {
    const departmentIndex: number = acc.findIndex((dep: OrderedDepartments) => dep.code === role.department.code);

    if (departmentIndex === -1) {
      acc.push({
        code: role.department.code,
        id: role.department.id,
        scenarios: [],
      });
    }

    const department: OrderedDepartments | undefined = acc.find((dep: OrderedDepartments) => dep.code === role.department.code);

    if(department !== undefined) {
      const scenarioIndex: number = department.scenarios.findIndex((sc : OrderedScenarios) => sc.code === role.scenario.code);

      if (scenarioIndex === -1) {
        department.scenarios.push({
          code: role.scenario.code,
          id: role.scenario.id,
          roles: [],
        });
      }

      const scenario: OrderedScenarios | undefined = department.scenarios.find((sc: OrderedScenarios) => sc.code === role.scenario.code);

      if(scenario !== undefined) {
        scenario.roles.push({
          code: role.role,
          id: role.id,
          supplier: (role.supplier != null) ? { id: role.supplier.id ,code: role.supplier.code, name: role.supplier.name } : null,

        });

        scenario.roles.sort((a: any, b: any) => {
          if (a.code !== b.code) {
            return a.code.localeCompare(b.code);
          }
          if (a.supplier?.name && b.supplier?.name) {
            return a.supplier.name.localeCompare(b.supplier.name);
          }

          return 0;
        });

        return acc;
      }

      return [];
    }

    return [];
  }, []) };
};

export { orderRoles };
