import type { ColorMode } from '../../services/Theme/ThemeContext';
import type { User } from '../../services/User/User';

import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '../../assets/images/qualichy_icon.svg';
import { useColorMode } from '../../services/Theme/ThemeContext';
import useUser from '../../services/User/UserService';
import { checkDownloadPdf } from '../../utils/checkDownloadPdf';

function UnAuthorized (): React.ReactElement {
  const { t } = useTranslation();
  const theme: ColorMode = useColorMode();
  const user: User = useUser();
  useEffect(() => {
    if(user.active_role?.role === 'PRODUCTION') {
      checkDownloadPdf();
    }
  },[]);

  return (
    <Box sx={{ width:'100%', display: 'flex', flexDirection:'column', alignContent:'center', alignItems:'center', py:2 }}>

      <Logo style={{ marginBottom: '1rem', width: '40%', height:'40%', fill:theme.mode === 'light'? '#000000' : '#ffffff' }} />
      <Typography variant='h1' fontSize='40px' >{t('unauthorized_title').toUpperCase()}</Typography>
      <Typography variant='h6' fontSize='20px'>{t('unauthorized_label')}</Typography>
    </Box>
  );
}

export default UnAuthorized;
