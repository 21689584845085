import type { OrderedDepartments, OrderedRole, OrderedRoles, OrderedScenarios, User, UserInfo, UserRole } from '../../../services/User/User';
import type { ErrorRespose } from '../../../utils/response';
import type { SelectChangeEvent, SxProps, Theme } from '@mui/material';
import type { AxiosError, AxiosResponse } from 'axios';
import type { Dispatch, SetStateAction } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';

import { FormControl, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { get, put } from '../../../services/Api/ApiFunctions';
import { useUserContext } from '../../../services/User/UserServiceProvider';
import { orderRoles } from '../../../utils/orderRoles';

interface Props {
  sxProp?: SxProps<Theme>;
  setRole: (newRole: number | string) => void;
  userRoleParent: number | string
  rolesListParent: UserRole[]
}

interface FormValues {
  role: number | string;
}

function SwitchRoleSelect({ sxProp, userRoleParent, rolesListParent }: Props): JSX.Element {
  const muiTheme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const [userInfo, setUserInfo]: [User, Dispatch<SetStateAction<User>>] = useUserContext();
  const [userRole, setUserRole] = useState<number | string>(userRoleParent);
  const [roleList, setRoleList] = useState<OrderedRoles | null>(null);
  const { control, setValue, getValues } = useForm<FormValues>({
    mode: 'all',
  });

  useEffect(() => {
    setRoleList(orderRoles(rolesListParent));
    setUserRole(userRoleParent);
  }, [rolesListParent, userRoleParent]);

  useEffect(() => {
    if (userRole !== '') {
      setValue('role', userRole);
    }
  }, [userRole]);

  const handleChange = (): void => {
    put('/users/me/active-role', { 'roleId': getValues('role') })
      .then(() => {
        get<UserInfo>('/users/me')
          .then((res: AxiosResponse<UserInfo, any> | undefined) => {
            if(res?.data !== undefined) {
              const active_role: UserRole | undefined = res.data.roles.find(
                (role: UserRole) => role.id === res.data.activeRoleId);
              const user: User = { ...userInfo, ...res.data, active_role:active_role ?? null };
              setUserInfo(user);
            }
          })
          .catch((error: AxiosError) => {
            const errorData: ErrorRespose = error.response?.data as ErrorRespose;
            console.log(errorData);
            toast.error(t('user_generic_error'));
          });
      })
      .catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };

  return (
    <Controller
      name="role"
      control={control}
      render={({ field }: {field: ControllerRenderProps<FormValues, 'role'>}): JSX.Element => (
        <FormControl sx={{ display: 'flex', justifyContent:'center', alignItems:'center', width: `${isMobile ? '200px':'auto'}` }}>
          <Select
            {...field}
            sx={{ ...sxProp, maxWidth: `${isMobile ? '200px':'auto'}` }}
            placeholder={t('role.label')}
            value={userRole}
            defaultValue={userRole}
            onChange={(event: SelectChangeEvent<number | string>): void => {
              field.onChange(event);
              handleChange();
            }}
          >
            {
              roleList?.departments?.map((dep: OrderedDepartments) => {
                return dep.scenarios.map((scenario: OrderedScenarios) => {
                  return scenario.roles.map((role: OrderedRole, idx: number) => {
                    return (<MenuItem value={role.id} key={idx}>{`${t(`${dep.code.toLowerCase()}_label`)} > ${t(`${scenario.code.toLowerCase()}_label`)} > ${t(`${role.code.toLowerCase()}_label`)}`}{role.supplier != null ? ` > ${role.supplier.name}` : ''}</MenuItem>);
                  });
                });
              })
            }
          </Select>
        </FormControl>
      )}
    />
  );
}

export default SwitchRoleSelect;
