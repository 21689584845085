import type { Dispatch, SetStateAction } from 'react';

import storageType from '../../utils/storageService';

import { type User, userInitialState } from './User';
import { useUserContext } from './UserServiceProvider';

const UserService = (): User => {
  const [user, setUser]: [User, Dispatch<SetStateAction<User>>] = useUserContext();

  const isFederatedLogin: boolean = storageType(
    process.env.REACT_APP_STORAGE ?? '',
  ).getItem('method') === 'third-party';

  return {
    name: user.name,
    surname: user.surname,
    email: user.email,
    language: user.language,
    activeRoleId: user.activeRoleId,
    active_role: user.active_role,
    isUserFederated: isFederatedLogin,
    roles: user.roles,
    logout: (): void => {
      setUser(userInitialState);
    },
  };
};

export default UserService;
