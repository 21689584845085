import type { PaletteMode } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import type { Context, Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';

import { ThemeProvider, createTheme } from '@mui/material';
import { createContext, useContext, useMemo, useState } from 'react';

import getTheme from '../../theme/theme';
import storageType from '../../utils/storageService';

interface ThemeProviderProps {
  children: ReactNode;
}

export interface ColorMode {
  toggleColorMode: () => void,
	mode: string
}

// type UserContextType = [ColorMode, Dispatch<SetStateAction<ColorMode>>];

export const ColorModeContext: Context<ColorMode> = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleColorMode: (): void => {},
  mode: 'light',
});

export const ThemeContextProvider = ({ children }: ThemeProviderProps): ReactElement => {
  const [mode, setMode]: [string, Dispatch<SetStateAction<string>>] = useState(storageType('local').getItem('theme') ?? 'light');

  const colorMode: ColorMode = useMemo(
    () => ({
      toggleColorMode: (): void => {
        setMode((prevMode: string) => {
          if (prevMode === 'light') {
            storageType('local').setItem('theme','dark');

            return 'dark';
          } else {
            storageType('local').setItem('theme','light');

            return 'light';
          }
        });
      },
      mode,
    }),
    [mode],
  );

  const theme: Theme = useMemo(() => createTheme(getTheme(mode as PaletteMode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const useColorMode = (): ColorMode => useContext(ColorModeContext);
