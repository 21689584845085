import i18n from '../i18n';

const base64URLEncode: (string: ArrayBuffer) => string = (string: ArrayBuffer) =>
  btoa(
    String.fromCharCode.apply(null, Array.from<number>(new Uint8Array(string))),
  )
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

const sha256: (str: string) => Promise<ArrayBuffer> = async (str: string) =>
  crypto.subtle.digest('SHA-256', new TextEncoder().encode(str));

const generateNonce: () => Promise<string> = async () => {
  const hash: ArrayBuffer = await sha256(
    crypto.getRandomValues(new Uint32Array(4)).toString(),
  );
  // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  const hashArray: number[] = Array.from(new Uint8Array(hash));

  return hashArray.map((b: number) => b.toString(16).padStart(2, '0')).join('');
};

const isTokenExpired = (token: string | null): boolean => {
  if(token === null) {
    throw new Error('Error');
  }

  return Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000;
};

const getLanguage = (): string => {
  return i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en';
};

export {
  base64URLEncode, generateNonce, getLanguage, isTokenExpired, sha256
};
