import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { IconButton } from '@mui/material';
import { useContext } from 'react';

import { ColorModeContext } from '../../../services/Theme/ThemeContext';

const ToggleTheme = (): JSX.Element => {
  const { mode, toggleColorMode } = useContext(ColorModeContext);

  const handleClick = (): void => {
    toggleColorMode();
  };

  return (
    <IconButton onClick={handleClick} color='inherit'>
      {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};
export default ToggleTheme;
