import type { Auth } from './Auth';
import type {
  Context,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';

import {
  createContext,
  useContext,
  useState,
} from 'react';

import storageType from '../../utils/storageService';

interface AuthProviderProps {
  children: ReactNode;
}

type AuthContextType = [Auth, Dispatch<SetStateAction<Auth>>];

const hasToken: string | null = storageType(
  process.env.REACT_APP_STORAGE ?? '',
).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`);

const userAttributesString: string | null = storageType(
  process.env.REACT_APP_STORAGE ?? '',
).getItem(`${process.env.REACT_APP_NAME ?? ''}-logged-user`);

const initState: Auth = {
  isLoggedIn: !!hasToken,
  userAttributes: userAttributesString
    ? JSON.parse(userAttributesString)
    : null,
  login: () => {
    throw new Error('Function not implemented.');
  },
  confirmNewPassword: () => {
    throw new Error('Function not implemented.');
  },
  forgotPasswordRequest: () => {
    throw new Error('Function not implemented.');
  },
  submitForgotPassword: () => {
    throw new Error('Function not implemented.');
  },
  currentSession: () => {
    throw new Error('Function not implemented.');
  },
  getSignInUserSession: () => {
    throw new Error('Function not implemented.');
  },
  logout: () => {
    throw new Error('Function not implemented.');
  },
  setIsLoggedIn: () => {
    throw new Error('Function not implemented.');
  },
  getIsLoggedIn: () => {
    throw new Error('Function not implemented.');
  },
  changeUserPassword: () => {
    throw new Error('Function not implemented.');
  },
  // getAttributes: () => {
  //   throw new Error('Function not implemented.');
  // },
  setUserAttributes: () => {
    throw new Error('Function not implemented.');
  },
  getUserAttributes: () => {
    throw new Error('Function not implemented.');
  },
  oAuthLogin(): void {
    throw new Error('Function not implemented.');
  },
  oAuthAttributes() {
    throw new Error('Function not implemented.');
  },
  oAuthLogout(): void {
    throw new Error('Function not implemented.');
  },
  oAuthTokenRefresh() {
    throw new Error('Function not implemented.');
  },
  getToken() {
    throw new Error('Function not implemented.');
  },
  getCurrentUser() {
    throw new Error('Function not implemented.');
  },
};
const AuthContext: Context<AuthContextType | null> = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: AuthProviderProps): ReactElement {
  const authState: [Auth, Dispatch<SetStateAction<Auth>>] = useState<Auth>(initState);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

function useAuthContext(): AuthContextType {
  const context: AuthContextType | null = useContext(AuthContext);
  if (context === null) {
    throw new Error('Please wrap this function into the AuthProvider');
  }

  return context;
}
export { AuthProvider, useAuthContext };
