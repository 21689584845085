import type Loading from './Loading';

import { useLoadingSpinnerContext } from './LoadingSpinnerServiceProvider';

export default function LoadingSpinnerService(): Loading {
  const [loading, setLoading] = useLoadingSpinnerContext();
  const initCounter: number = 0;
  const disabledAll: boolean = false;

  return {
    loadingBlocker: disabledAll,
    counter: initCounter,
    addRequest(): void {
      setLoading(this.counter + 1);
    },
    addResponse(): void {
      setLoading(this.counter - 1);
    },
    getCounter(): number {
      return loading;
    },
    setLoadingBlocker(val: boolean): void {
      this.loadingBlocker = val;
    },
  };
}
