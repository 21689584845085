import type { Auth } from '../../services/Auth/Auth';
import type { User } from '../../services/User/User';

import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import useAuth from '../../services/Auth/AuthService';
import useUser from '../../services/User/UserService';

const DownloadPdf = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const auth: Auth = useAuth();
  const user: User = useUser();

  useEffect(() => {
    searchParams.forEach((val: string, key: string) => {
      localStorage.setItem(`download-pdf-${key}`, val);
    });
  }, []);

  return auth.isLoggedIn ? (
    user.active_role?.role === 'INSPECTOR' ? (
      <Navigate to="/orders" replace />
    ) : (
      <Navigate to="/home" replace />
    )
  ) : (
    <Navigate to="/login" replace />
  );
};

export default DownloadPdf;
