import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom/client';

import AmplifyConfig from './amplify-config';
import App from './app/App';
import './i18n';
import './index.scss';
import { AuthProvider } from './services/Auth/AuthServiceProvider';
import { LoadingSpinnerProvider } from './services/LoadingSpinner/LoadingSpinnerServiceProvider';

Amplify.configure(AmplifyConfig);

const rootHTMLElement: HTMLElement | null = document.getElementById('root');

if (rootHTMLElement !== null) {
  const root: ReactDOM.Root = ReactDOM.createRoot(
    rootHTMLElement,
  );
  root.render(
    <AuthProvider>
      <LoadingSpinnerProvider>
        <App />
      </LoadingSpinnerProvider>
    </AuthProvider>,
  );
}
