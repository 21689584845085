import type { AuthSession, JWT } from 'aws-amplify/auth';
import type { InternalAxiosRequestConfig } from 'axios';
import type { Auth } from '../Auth/Auth';
import type Loading from '../LoadingSpinner/Loading';

import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { isTokenExpired } from '../../utils/helpers';
import storageType from '../../utils/storageService';

import useAuth from '../Auth/AuthService';
import useLoadingSpinnerContext from '../LoadingSpinner/LoadingSpinnerService';

export default function RequestInterceptor(): null {
  const [isActive, setIsActive] = useState<boolean>(false);
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const auth: Auth = useAuth();

  async function getToken(): Promise<string> {
    try {
      if (
        storageType(
          process.env.REACT_APP_STORAGE ?? 'local',
        ).getItem('method') === 'third-party'
      ) {
        if (
          isTokenExpired(
            storageType(
              process.env.REACT_APP_STORAGE ?? 'local',
            ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`),
          )
        ) {
          const token: string | undefined = await auth.oAuthTokenRefresh();
          if (token !== undefined) {
            storageType(
              process.env.REACT_APP_STORAGE ?? 'local',
            ).setItem(`${process.env.REACT_APP_NAME ?? ''}-token`, token);

            return token;
          }

          return '';
        }

        return (
          storageType(
            process.env.REACT_APP_STORAGE ?? 'local',
          ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`) ?? ''
        );
      }
      const session: AuthSession = await fetchAuthSession();
      if (session.tokens != null) {
        const token: JWT | undefined = session.tokens.idToken;
        if (token !== undefined) {
          storageType(
            process.env.REACT_APP_STORAGE ?? 'local',
          ).setItem(`${process.env.REACT_APP_NAME ?? ''}-token`, token.toString());

          return token.toString();
        }

        return '';
      }

      return '';
    } catch {
      storageType(
        process.env.REACT_APP_STORAGE ?? 'local',
      ).removeItem('givenchy-quality-platform-webapp-token');
      window.location.href = '/login';

      return '';
    }
  }

  const initializeInterceptor = (): any => {
    setIsActive(true);

    return axios.interceptors.request.use(
      async (request: InternalAxiosRequestConfig) => {
        if ((request.url?.includes(process.env.REACT_APP_ENDPOINT_ROOTAPI ?? '')) === true) {
          loadingSpinner.addRequest();
          request.headers.Authorization = `Bearer ${await getToken()}`;
        }
        if (request.headers.Authorization === 'Bearer ') {
          storageType(
            process.env.REACT_APP_STORAGE ?? 'local',
          ).removeItem('givenchy-quality-platform-webapp-token');
          window.location.href = '/login';
        }

        return request;
      },
      (error: any) => {
        loadingSpinner.addResponse();
        void Promise.reject(error);
      },
    );
  };

  useEffect(() => {
    if (!isActive) {
      initializeInterceptor();
    }
  }, []);

  return null;
}
