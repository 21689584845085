import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { getLocalization } from './services/Api/ApiFunctions';

const loadResources= async (lng: string): Promise<any> => {
  return getLocalization(`/client/keyset/${lng}`, new URLSearchParams({ 'format':'OBJECT', 'useFallback':'true' }))
    .then((response: any) => {
      if(response != null) {
        return response.data;
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
};

const backendOptions: any = {
  loadPath: '{{lng}}|{{ns}}',
  request: (_options:any, url:any, _payload:any, callback:any): void => {
    try {
      const [lng]: string[] = url.split('|');
      void loadResources(lng).then((response: any) => {
        callback(null, {
          data: response?.keySet || '',
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
// lazy load in memory translations
// pass the i18n instance to react-i18next.
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'currentOnly',
    fallbackLng: false,
    detection: {
      order: ['navigator', 'localStorage'],
    },
    backend: {
      backends: [
        // trAPP http request
        HttpBackend,
        // lazy load in memory translations
        resourcesToBackend(async (language: string) => import(`../public/locales/${language}.json`)),
      ],
      backendOptions: [backendOptions],
    },
  }).catch(() => {
    throw new Error('i18n initialization failed!');
  });

export default i18n;
