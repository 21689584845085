import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ForgotPasswordDialogProps {
  open: boolean;
  onClose: (returnValue: string) => void;
}

export interface FormValues {
  email: string;
}

function ForgotPasswordDialog({ open, onClose }: ForgotPasswordDialogProps): JSX.Element {
  const { t } = useTranslation();
  const { register, formState: { errors, isValid }, getValues } = useForm<FormValues>({ mode: 'all' });

  return (
    <Dialog open={open} onClose={(): void => onClose('closed')} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography fontWeight='bold' typography='h5' sx={{ pt: 1 }}>{t('forgotpassword_recoverpassword_title')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: 'right' }} onClick={(): void => onClose('closed')}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('forgotpassword_recoverinstructions_label')}</Typography>
        <form>
          <TextField fullWidth autoFocus label={t('forgotpassword_recoveremail_label')} placeholder={t('forgotpassword_recoveremail_label')} autoComplete="email" variant="outlined" sx={{ my: 2 }} {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })} helperText={errors.email?.type === 'required' ? t('error_requiredfield_helpertext') : errors.email?.type === 'pattern' ? t('error_login_invalidemail_helpertext') : ''} />
        </form>
        <Typography>{t('forgotpassword_spamwarning_label')}</Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant='contained' color='error' onClick={(): void => onClose('closed')}>{t('alert_actionbutton_cancel')}</Button>
        <Button disabled={!isValid} variant='contained' color='primary' onClick={(): void => onClose(getValues('email'))}>{t('forgotpassword_sendrequest_action')}</Button>
      </DialogActions>
    </Dialog >
  );
}

export default ForgotPasswordDialog;
