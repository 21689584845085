import type Loading from '../../../services/LoadingSpinner/Loading';
import type { Image } from '../../../utils/lists';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useLoadingSpinnerContext from '../../../services/LoadingSpinner/LoadingSpinnerService';

interface PhotosDialogProps {
  open: boolean;
  onClose: () => void;
  photos: Image[];
}

function PhotosDialog({ open, onClose, photos }: PhotosDialogProps): JSX.Element {
  const { t } = useTranslation();
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const [index, setIndex] = useState(0);

  const closeDialog = (): void => {
    onClose();
    setIndex(0);
  };

  return (
    <>
      <Dialog open={open} onClose={closeDialog} maxWidth='md' fullWidth>
        <DialogTitle>
          <Grid container>
            <Grid item xs={10}>
              <Typography fontWeight='bold' typography='h5' sx={{ pt: 1 }}>{t('defectsdetail_photos_label')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton sx={{ float: 'right' }} onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ px: 0 }}>
          {photos.length !== 0 &&
          <Grid container>
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {index >= 1 ? <div onClick={(): void => {
                loadingSpinner.addRequest();
                setIndex(index - 1);
                loadingSpinner.addResponse();
              }}>
                <IconButton sx={{ mr:1 }}>
                  <ChevronLeftIcon sx={{ height:'40px', width:'40px' }} />
                </IconButton>
              </div> : null}
            </Grid>
            <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
              <img style={{ maxHeight: '32rem' }} src={photos[index].presignedUrl} />
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {index < photos.length - 1 ? <div onClick={(): void => {
                loadingSpinner.addRequest();
                setIndex(index + 1);
                loadingSpinner.addResponse();
              }}>
                <IconButton sx={{ ml:1 }}>
                  <ChevronRightIcon sx={{ height:'40px', width:'40px' }} />
                </IconButton>
              </div> : null}
            </Grid>
          </Grid>
          }
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PhotosDialog;
