import type Loading from '../../services/LoadingSpinner/Loading';
import type { User } from '../../services/User/User';
import type { OrderItem, Schedule } from '../../utils/lists';
import type { ErrorRespose, OrderItemResponse } from '../../utils/response';
import type { Theme } from '@mui/material';
import type { AxiosError } from 'axios';
import type { Country } from 'country-code-lookup';
import type { Dayjs } from 'dayjs';
import type { ChangeEvent } from 'react';
import type { ControllerFieldState, ControllerRenderProps, SubmitHandler } from 'react-hook-form';

import { Avatar, Box, Button, Card, FormControl, Grid, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import lookup from 'country-code-lookup';
import Holidays from 'date-holidays';
import dayjs from 'dayjs';
import { type Dispatch, type MouseEvent, type SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import GivenchyLogo from '../../assets/images/givenchy.svg';
import { post } from '../../services/Api/ApiFunctions';
import useLoadingSpinnerContext from '../../services/LoadingSpinner/LoadingSpinnerService';
import useUser from '../../services/User/UserService';
import storageType from '../../utils/storageService';

import ScheduleConfirmDialog from '../dialogs/ScheduleConfirmDialog/ScheduleConfirmDialog';

interface OrderItemListProps {
  orderItemListResponse: OrderItemResponse | undefined;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  fetchTable: () => void;
}

interface TableRowProps {
  orderItem: OrderItem
  fetchTable: () => void
}

interface ScheduleNotValidResponse {
  count: number,
  sum: number,
  overflow: boolean
}

interface SchedulationError {
  response: ScheduleNotValidResponse,
  status: number,
  options: any,
  message: string,
  name: string
}

interface SupplierInspectionsInfo {
  count: number,
  overflow: boolean,
  sum: number
}

export interface FormValues {
  orderitemId: number,
  quantity: number;
  date: Dayjs | null;
}

const StyledTableRow: any = styled(TableRow)(({ theme }:{ theme: Theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function OrderItemList({
  orderItemListResponse,
  pageNumber,
  setPageNumber,
  fetchTable,
}: OrderItemListProps): JSX.Element {
  const { t } = useTranslation();
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const [orderItemList, setOrderItemList] = useState<OrderItem[] | undefined>(undefined);

  const handleChangePage = (event: MouseEvent<HTMLElement> | null, page: number) : void => {
    setPageNumber(page);
  };

  useEffect(() => {
    loadingSpinner.addRequest();
    setOrderItemList(orderItemListResponse?.items);
    loadingSpinner.addResponse();
  }, [orderItemListResponse]);

  return (
    orderItemList !== undefined && orderItemList.length !== 0 ?
      <Box>
        <Card sx={{ boxShadow: 3, borderRadius: '10px' }}>
          <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
              <Table size="small" sx={{ overflowX:'auto', minWidth:'840px' }}>
                <TableBody>
                  {orderItemList.map((orderItem: OrderItem) => (
                    <TableRowForm key={orderItem.id} orderItem={orderItem} fetchTable={fetchTable} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={Math.ceil(orderItemListResponse?.totalCount ?? 0 / 10)}
              rowsPerPage={orderItemListResponse?.paginationInfo.limit ?? 0}
              page={pageNumber}
              onPageChange={handleChangePage}
            />
          </Box>
        </Card>
      </Box>
      : <Typography variant="h3" color='#8f9bb3'>{t('dashboard_orderitemlist_empty_label')}</Typography>
  );
};

function TableRowForm ({ orderItem, fetchTable }: TableRowProps): JSX.Element {
  const { t } = useTranslation();
  const user: User = useUser();
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const [openScheduleConfirmDialog, setOpenScheduleConfirmDialog] = useState(false);
  const [supplierInspectionsInfo, setSupplierInspectionsInfo] = useState<SupplierInspectionsInfo>();
  const country: Country | null = lookup.byCountry(user.active_role?.supplier?.country ?? '');
  const hd: Holidays = new Holidays(country?.iso2 ?? '');
  const { register, handleSubmit, reset, getValues, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'all',
  });

  const onSubmitConfirmDialog = (): void => {
    post('/testing-rounds', { 'orderitemId': orderItem.id ,'date': getValues().date?.utcOffset(2, true).startOf('date').format(), 'quantity': getValues().quantity }, new URLSearchParams({ 'forceCreate':'true' }))
      .then((): PromiseLike<void> | void => {
        toast.success(t('schedule_new_round_success'));
        loadingSpinner.addRequest();
        reset();
        fetchTable();
        loadingSpinner.addResponse();
      })
      .catch((error: AxiosError) => {
        const schedulationError: SchedulationError | undefined = error.response?.data as SchedulationError | undefined;
        if(schedulationError !== undefined) {
          if(Array.isArray(schedulationError.message)) {
            setOpenScheduleConfirmDialog(true);
          } else {
            toast.error(t(schedulationError.message.toLowerCase()));
          }
        } else {
          const errorData: ErrorRespose = error.response?.data as ErrorRespose;
          console.log('error',errorData);
          toast.error(t(errorData.message.toLowerCase()));
        }
      });
  };

  const onSubmit: SubmitHandler<FormValues> = (formValue: FormValues) => {
    post('/testing-rounds', { ...formValue, date: formValue.date?.utcOffset(2, true).startOf('date').format() })
      .then((): PromiseLike<void> | void => {
        toast.success(t('schedule_new_round_success'));
        loadingSpinner.addRequest();
        reset();
        fetchTable();
        loadingSpinner.addResponse();
      })
      .catch((error: AxiosError) => {
        const schedulationError: SchedulationError | undefined = error.response?.data as SchedulationError | undefined;
        if(schedulationError !== undefined && schedulationError.response.count > 0) {
          const similarInspections :SupplierInspectionsInfo = schedulationError.response;
          setSupplierInspectionsInfo(similarInspections);
          setOpenScheduleConfirmDialog(true);
        } else {
          const errorData: ErrorRespose = error.response?.data as ErrorRespose;
          console.log(errorData);
          toast.error(t(errorData.message.toLowerCase()));
        }
      });
  };

  const validateDate = (day: dayjs.Dayjs): boolean => {
    return day.startOf('date').format() < dayjs().startOf('date').format() || [0].includes(day.day());
  };

  return(
    <StyledTableRow key={orderItem.id}>
      <TableCell align='left' sx={{ p: 3 }}>
        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('orderitemcode_field_label')}</Typography>
            <Typography>{orderItem.number}</Typography>
          </Grid>
          <Grid item md={12} lg={2}>
            <Typography fontWeight={'bold'}>{t('testinground_row_label')}:</Typography>
            <Typography>{orderItem.item}</Typography>
          </Grid>
          {
            user.active_role?.role !== 'SUPPLIER' ? <Grid item md={12} lg={6}>
              <Typography fontWeight={'bold'}>{t('deliverydate_field_label')}</Typography>
              <Typography>{dayjs(orderItem.deliveryDate).utc().local().format('LL')}</Typography>
            </Grid> : null
          }
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('testedquantity_field_label')}:</Typography>
            <Typography>{`${orderItem.requiredFromSupplierQuantity} / ${orderItem.orderedQuantity}`}</Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align='center' sx={{ p: 3, display: { xs: 'none', md: 'table-cell' } }}>
        <div onClick={(): void => {
          if (orderItem.sku.imageUrl) {
            window.open(`${process.env.REACT_APP_ENDPOINT_ROOTAPI ?? ''}${orderItem.sku.imageUrl}&token=${storageType(
              process.env.REACT_APP_STORAGE ?? 'local',
            ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`) ?? ''}`, '_blank');
          }
        }} style={{ cursor: orderItem.sku.imageUrl ? 'pointer' : 'cursor' }}>
          <Avatar sx={{ width: 75, height: 75, backgroundColor: `${orderItem.sku.imageUrl !== '' ? '#F6F6F6' : ''}` }}>
            {
              <img width="70px"
                onError={(e: any): void => {
                  e.target.onerror = null; // prevents looping
                  e.target.src=GivenchyLogo;
                  e.target.style.width='40px';
                }}
                alt='givenchy logo' src={`${process.env.REACT_APP_ENDPOINT_ROOTAPI ?? ''}${orderItem.sku.imageUrl}&token=${storageType(
                  process.env.REACT_APP_STORAGE ?? 'local',
                ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`) ?? ''}`} />
            }
          </Avatar>
        </div>
      </TableCell>
      <TableCell align='left' sx={{ p: 3 }}>
        <Grid container spacing={1}>
          <Grid item lg={6}>
            <Typography fontWeight={'bold'}>{t('testinground_line_label')}:</Typography>
            <Typography>{orderItem.sku.line}</Typography>
          </Grid>
          <Grid item lg={6}>
            <Typography fontWeight={'bold'}>{t('dashboard_sku_label')}:</Typography>
            <Typography>{orderItem.sku.code}{orderItem.sku.color.code}</Typography>
          </Grid>
          <Grid item lg={6}>
            <Typography fontWeight={'bold'}>{t('description_field_label')}</Typography>
            <Typography>{orderItem.sku.description}</Typography>
          </Grid>
          <Grid item lg={6}>
            <Typography fontWeight={'bold'}>{t('testinground_colordescription_label')}:</Typography>
            <Typography>{orderItem.sku.color.description}</Typography>
          </Grid>
          <Grid item lg={6}>
            <Typography fontWeight={'bold'}>{t('testinground_collectiondescription_label')}:</Typography>
            <Typography>{`${orderItem.collection.description} (${orderItem.collection.code})`}</Typography>
          </Grid>
          <Grid item lg={6}>
            <Typography fontWeight={'bold'}>{t('size_field_label')}:</Typography>
            <Typography>{orderItem.schedules.length === 0 ? t('testinground_uniquesize_label'): orderItem.schedules.map((schedule: Schedule) => schedule.size).join(', ').length !== 0 ? orderItem.schedules.map((schedule: Schedule) => schedule.size).join(', ') : t('testinground_uniquesize_label')}</Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align='right' sx={{ p: 3, display:'flex', flexDirection:'column', alignItems:'end', justifyContent:'center', border: 0 }}>
        <form onSubmit={handleSubmit((data: FormValues)=> onSubmit({ ...data, orderitemId: orderItem.id }))}>
          <FormControl>
            <Controller
              name="quantity"
              defaultValue={1}
              control={control}
              render={({ field, fieldState: { error } }: any): JSX.Element =>
                <TextField
                  {...field}
                  type='number'
                  {...register('quantity', { required: true, max: ((orderItem.orderedQuantity)-(orderItem.requiredFromSupplierQuantity)), min: 1 })}
                  sx={{ mb: 2, minWidth:'50px' }}
                  variant="outlined"
                  InputProps={{ inputProps: { min: 1, max: ((orderItem.orderedQuantity)-(orderItem.requiredFromSupplierQuantity)) } }}
                  label={t('quantity_field_label')}
                  fullWidth
                  placeholder='0'
                  autoComplete="quantity"
                  error={error != null}
                  helperText={errors.quantity?.type === 'required' ? t('error_requiredfield_helpertext') : errors.quantity?.type === 'max' || errors.quantity?.type === 'min' ? t('error_overflow_quantity') : ''}
                  onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                    field.onChange(e.target.value ? Number(e.target.value) : '');
                  }}
                />}
            />
          </FormControl>
          <FormControl>
            <Controller
              name="date"
              defaultValue={[6].includes(dayjs().day()) ? dayjs().add(2,'day'): dayjs()}
              control={control}
              rules={{
                required: true,
                validate: (value: Dayjs | null) => value != null ? !validateDate(value) : true,
              }}
              render={({ field, fieldState: { error } }: {field: ControllerRenderProps<FormValues, 'date'>, fieldState: ControllerFieldState}): JSX.Element => (
                <DatePicker
                  {...register('date')}
                  {...field}
                  sx={{ mb: 2 }}
                  format='DD/MM/YYYY'
                  shouldDisableDate={(day: dayjs.Dayjs): boolean => day < dayjs().add(-1,'day') || [0].includes(day.day()) || Boolean(hd.isHoliday(day.toDate()))}
                  slotProps={{
                    textField: {
                      error: !(error == null && errors.date == null),
                      helperText: errors.date?.type === 'required' && t('error_requiredfield_helpertext') || errors.date?.type === 'validate' && t('date_not_greater_than_that_into_round'),
                      label: t('testinground_requestdate_label'),
                    },
                  }}
                />
              )}
            />
          </FormControl>
          <Button type="submit" variant="contained" color='primary' disabled={!isValid} >{t('schedule_action')}</Button>
        </form>
        <ScheduleConfirmDialog
          open={openScheduleConfirmDialog}
          onClose={(): void => setOpenScheduleConfirmDialog(false)}
          fetchTable={fetchTable}
          scheduleInfo={supplierInspectionsInfo}
          onSubmitConfirmDialog={onSubmitConfirmDialog}
        />
      </TableCell>
    </StyledTableRow>

  );
}

export default OrderItemList;
