import type Loading from '../../services/LoadingSpinner/Loading';
import type { User } from '../../services/User/User';
import type {
  Lot,
  LotRound,
  NonCompliances,
  Order,
  Resource,
} from '../../utils/lists';
import type { ErrorRespose, OrderResponse } from '../../utils/response';
import type { Theme } from '@mui/material';
import type { AxiosError, AxiosResponse } from 'axios';
import type { SyntheticEvent } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  type Dispatch,
  type MouseEvent,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import GivenchyLogo from '../../assets/images/givenchy.svg';
import { get } from '../../services/Api/ApiFunctions';
import useLoadingSpinnerContext from '../../services/LoadingSpinner/LoadingSpinnerService';
import useUser from '../../services/User/UserService';
import { brand } from '../../theme/theme';
import storageType from '../../utils/storageService';

import DefectsDialog from '../dialogs/DefectsDialog/DefectsDialog';
import ResourcesDialog from '../dialogs/ResourcesDialog/ResourcesDialog';

interface OrderListProps {
  orderListResponse: OrderResponse | undefined;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  fetchData: () => void;
}

interface OrderRowProps {
  order: Order;
}

interface LotRowProps {
  lot: Lot;
  orderLotsNumber: number;
  lotIndex: number;
}

interface RoundRowProps {
  round: LotRound;
}

const StyledOrderTableRow: any = styled(TableRow)(
  ({ theme }: { theme: Theme }) => ({
    '&:nth-of-type(odd) > .MuiTableCell-root > .MuiPaper-root': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }),
);

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function OrderList({
  orderListResponse,
  pageNumber,
  setPageNumber,
}: OrderListProps): JSX.Element {
  const { t } = useTranslation();
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const [orderList, setOrderList] = useState<Order[] | undefined>(undefined);

  const handleChangePage = (
    event: MouseEvent<HTMLElement> | null,
    page: number,
  ): void => {
    setPageNumber(page);
  };

  useEffect(() => {
    loadingSpinner.addRequest();
    setOrderList(orderListResponse?.items);
    loadingSpinner.addResponse();
  }, [orderListResponse]);

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', pt: 2 }}>
      <TableContainer>
        <Table
          size="small"
          style={{ tableLayout: 'fixed', borderSpacing: 0, overflowX: 'auto' }}
        >
          <TableBody>
            {orderList !== undefined && orderList.length !== 0 ? (
              orderList.map((order: Order) => (
                <OrderRowForm key={order.id} order={order} />
              ))
            ) : (
              <TableRow sx={{ p: 0 }}>
                <TableCell
                  align="center"
                  colSpan={4}
                  sx={{ px: 0, py: 3, textAlign: 'center', border: 0 }}
                >
                  <Typography variant="h3" align="center" color="#8f9bb3">
                    {t('dashboard_orderitemlist_empty_label')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={Math.ceil(orderListResponse?.totalCount ?? 0 / 10)}
        rowsPerPage={orderListResponse?.paginationInfo.limit ?? 0}
        page={pageNumber}
        onPageChange={handleChangePage}
      />
    </Box>
  );
}

function OrderRowForm({ order }: OrderRowProps): JSX.Element {
  const user: User = useUser();
  const { t } = useTranslation();

  const [lotList, setLotList] = useState<Lot[] | undefined>(undefined);

  const handleFetchLots = (orderId: number): void => {
    get<OrderResponse>(`/orderitems/${orderId}/lots`)
      .then((res: AxiosResponse | undefined): void => {
        if (res !== undefined) {
          setLotList(res.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });
  };

  return (
    <TableRow sx={{ p: 0 }}>
      <TableCell
        align="center"
        colSpan={4}
        sx={{ p: 1, borderRadius: 0, border: 0 }}
      >
        <Accordion
          sx={{ boxShadow: 2, borderRadius: 0, border: 0 }}
          onChange={(e: SyntheticEvent, expanded: boolean): void => {
            if (expanded) {
              handleFetchLots(order.id);
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            sx={{ pl: 3, pr: 1 }}
          >
            <Table sx={{ borderSpacing: 0 }}>
              <TableBody>
                <TableRow>
                  <TableCell align="left" sx={{ border: 0 }}>
                    <Grid container columnSpacing={3} rowSpacing={4}>
                      <Grid
                        alignContent={'center'}
                        item
                        md={12}
                        lg={6}
                        width={'200px'}
                      >
                        <Typography fontWeight={'bold'}>
                          {`${t('ordercode_label')}:`}&nbsp;
                        </Typography>
                        <Typography>{`${order.number} (${order.item})`}</Typography>
                      </Grid>
                      {user.active_role?.role === 'COORDINATOR' && (
                        <Grid
                          alignContent={'center'}
                          item
                          md={12}
                          lg={6}
                          width={'200px'}
                        >
                          <Typography fontWeight={'bold'}>
                            {t('supplier_label')}:
                          </Typography>
                          <Typography>{order.supplier.name}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell align="center" sx={{ px: 3, border: 0 }}>
                    <div
                      onClick={(): void => {
                        if (order.sku.imageUrl) {
                          window.open(`${process.env.REACT_APP_ENDPOINT_ROOTAPI ?? ''}${order.sku.imageUrl}&token=${storageType(
                            process.env.REACT_APP_STORAGE ?? 'local',
                          ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`) ?? ''}`, '_blank');
                        }
                      }}
                      style={{
                        cursor: order.sku.imageUrl ? 'pointer' : 'cursor',
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 75,
                          height: 75,
                          backgroundColor: `${order.sku.imageUrl !== '' ? '#F6F6F6' : ''}`,
                        }}
                      >
                        <img
                          width="70px"
                          alt="givenchy logo"
                          onError={(e: any): void => {
                            e.target.onerror = null; // prevents looping
                            e.target.src=GivenchyLogo;
                            e.target.style.width='40px';
                          }}
                          src={`${process.env.REACT_APP_ENDPOINT_ROOTAPI ?? ''}${order.sku.imageUrl}&token=${storageType(
                            process.env.REACT_APP_STORAGE ?? 'local',
                          ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`) ?? ''}`}
                        />
                      </Avatar>
                    </div>
                  </TableCell>
                  <TableCell align="left" sx={{ px: 3, border: 0 }}>
                    <Grid container columnSpacing={2} rowSpacing={1}>
                      <Grid item md={12} lg={6} width={'200px'}>
                        <Typography fontWeight={'bold'}>
                          {t('dashboard_sku_label')}:
                        </Typography>
                        <Typography>
                          {order.sku.code + order.sku.color.code}
                        </Typography>
                      </Grid>
                      <Grid item md={12} lg={6} width={'200px'}>
                        <Typography fontWeight={'bold'}>
                          {t('description_field_label')}
                        </Typography>
                        <Typography>{order.sku.description}</Typography>
                      </Grid>
                      <Grid item md={12} lg={6} width={'200px'}>
                        <Typography fontWeight={'bold'}>
                          {t('testinground_colordescription_label')}:
                        </Typography>
                        <Typography>{order.sku.color.description}</Typography>
                      </Grid>
                      <Grid item md={12} lg={6} width={'200px'}>
                        <Typography fontWeight={'bold'}>
                          {`${t('testinground_collectiondescription_label')}:`}
                          &nbsp;
                        </Typography>
                        <Typography>{`${order.collection.description} (${order.collection.code})`}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="left" sx={{ px: 3, border: 0 }}>
                    <Grid container columnSpacing={2} rowSpacing={1}>
                      <Grid item md={12} lg={6} width={'200px'}>
                        <Typography fontWeight={'bold'}>
                          {`${t('orderlist_ordered_quantity_label')}:`}&nbsp;
                        </Typography>
                        <Typography>{order.orderedQuantity}</Typography>
                      </Grid>
                      <Grid item md={12} lg={6} width={'200px'}>
                        <Typography fontWeight={'bold'}>
                          {`${t('orderlist_required_quantity_label')}:`}&nbsp;
                        </Typography>
                        <Typography>
                          {order.requiredFromSupplierQuantity}
                        </Typography>
                      </Grid>
                      <Grid item md={12} lg={6} width={'200px'}>
                        <Typography fontWeight={'bold'}>
                          {`${t('orderlist_tested_quantity_label')}:`}&nbsp;
                        </Typography>
                        <Typography>{order.testedQuantity}</Typography>
                      </Grid>
                      <Grid item md={12} lg={6} width={'200px'}>
                        <Typography fontWeight={'bold'}>
                          {`${t('orderlist_deliverydate_order_label')}:`}&nbsp;
                        </Typography>
                        <Typography>
                          {dayjs(order.deliveryDate).format('DD/MM/YYYY')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionSummary>
          <AccordionDetails>
            {lotList != null && lotList.length > 0 ? (
              <Table
                style={{
                  tableLayout: 'fixed',
                  borderSpacing: 0,
                  height: '100%',
                }}
              >
                <TableBody>
                  {lotList.map((lot: Lot, index: number) => (
                    <LotRowForm
                      key={lot.id}
                      lot={lot}
                      orderLotsNumber={lotList.length}
                      lotIndex={index}
                    />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 2,
                }}
              >
                <Typography variant="h3" align="center" color="#8f9bb3">
                  {t('dashboard_orderlotlist_empty_label')}
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </TableCell>
    </TableRow>
  );
}
function LotRowForm({
  lot,
  orderLotsNumber,
  lotIndex,
}: LotRowProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledOrderTableRow sx={{ p: 0 }}>
      <TableCell align="center" sx={{ border: 0 }}>
        <Accordion sx={{ boxShadow: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
          >
            <Table
              style={{ tableLayout: 'fixed', borderSpacing: 0, height: '100%' }}
            >
              {/* <Table sx={{ borderSpacing: 0, tableLayout: 'fixed' }}> */}
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: 0 }}>
                    <Box sx={{ display: 'inline-flex' }}>
                      <Typography fontWeight={'bold'}>
                        {t('lot_number_label')}:&nbsp;
                      </Typography>
                      <Typography alignContent={'center'}>
                        {orderLotsNumber - lotIndex}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ border: 0 }}>
                    <Box sx={{ display: 'inline-flex' }}>
                      <Typography fontWeight={'bold'}>
                        {t('testinground_requestdate_label')}:&nbsp;
                      </Typography>
                      <Typography alignContent={'center'}>
                        {dayjs(lot.testingRoundList[0].requestDate).format(
                          'DD/MM/YYYY',
                        )}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ border: 0 }}>
                    <Box sx={{ display: 'inline-flex' }}>
                      <Typography fontWeight={'bold'}>
                        {t('testinground_testingdate_label')}:&nbsp;
                      </Typography>
                      <Typography alignContent={'center'}>
                        {dayjs(lot.testingRoundList[0].lastEditDate).format(
                          'DD/MM/YYYY',
                        )}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ border: 0 }}>
                    <Box sx={{ display: 'inline-flex' }}>
                      <Typography fontWeight={'bold'}>
                        {t('orderlist_status_order_label')}:&nbsp;
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            backgroundColor: `${getLotStatusColor(lot.testingRoundList)}`,
                          }}
                        />
                        <Typography fontWeight={'bold'}>
                          {t(getLotStatus(lot.testingRoundList))}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ border: 0 }}>
                    <Box>
                      <Box sx={{ display: 'inline-flex' }}>
                        <Typography fontWeight={'bold'}>
                          {lot.testingRoundList[0].isEnded
                            ? t('inspection_realquantity_label')
                            : t('requestedquantity_field_label')}
                          :&nbsp;
                        </Typography>
                        <Typography>
                          {lot.testingRoundList[0].realQuantity}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionSummary>
          <AccordionDetails>
            {lot.testingRoundList.length > 0 ? (
              <Table
                style={{
                  tableLayout: 'fixed',
                  borderSpacing: 0,
                  height: '100%',
                }}
              >
                <TableBody>
                  {lot.testingRoundList.map((round: LotRound) => (
                    <RoundRowForm key={round.id} round={round} />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography variant="h3" align="center" color="#8f9bb3">
                  {t('dashboard_orderlotlist_empty_label')}
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </TableCell>
    </StyledOrderTableRow>
  );
}

function RoundRowForm({ round }: RoundRowProps): JSX.Element {
  const { t } = useTranslation();

  const [openDefectsDialog, setOpenDefectsDialog] = useState<boolean>(false);
  const [defects, setDefects] = useState<NonCompliances[]>([]);
  const [openResourcesDialog, setOpenResourcesDialog] =
    useState<boolean>(false);
  const [resources, setResources] = useState<Resource[]>([]);

  const handleReportClick = (): void => {
    window.open(round.reportUrl ?? '', '_blank', 'noreferrer');
  };

  const handleResourcesClick = (roundId: number): void => {
    get<Resource[]>(`/testing-rounds/${roundId}/resources`)
      .then((res: AxiosResponse<Resource[], any> | undefined): void => {
        if (res != null) {
          setResources(res.data);
          setOpenResourcesDialog(true);
        }
      })
      .catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };

  const handleNonCompliancesClick = (roundId: number): void => {
    get<NonCompliances[]>(`/testing-rounds/${roundId}/non-compliances`)
      .then((res: AxiosResponse<NonCompliances[], any> | undefined): void => {
        if (res != null) {
          setDefects(res.data);
          setOpenDefectsDialog(true);
        }
      })
      .catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };

  return (
    <>
      <StyledTableRow key={round.id}>
        <TableCell align="center">
          <Typography fontWeight={'bold'}>
            {t('round_number_label')}:&nbsp;
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              p: 1,
            }}
          >
            <Avatar sx={{ width: 50, height: 50 }}>
              <Typography fontSize="2.25rem">{round.number}</Typography>
            </Avatar>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={'bold'}>
            {t('orderlist_status_order_label')}:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: `${getRoundStatusColor(round)}`,
              }}
            />
            <Typography fontWeight={'bold'}>{t(getRoundStatus(round))}</Typography>
          </Box>
          <Typography fontWeight={'bold'}>
            {t('testinground_testingdate_label')}:
          </Typography>
          <Typography>
            {dayjs(round.lastEditDate).format('DD/MM/YYYY')}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={'bold'}>{t('input_type_label')}:</Typography>
          <Typography>
            {round.testingType.type === 'AQL' ||
            round.testingType.type === 'PERCENTAGE'
              ? `${t(`type_${round.testingType.type.toLowerCase()}_label`)} ${round.testingSubtype !== null ? round.testingSubtype.subtype : ''}`
              : t(`type_${round.testingType.type.toLowerCase()}_label`)}
          </Typography>
          <Typography fontWeight={'bold'}>
            {t('acceptedquantity_field_label')}:
          </Typography>
          <Typography>
            {round.acceptedQuantity !== null ? round.acceptedQuantity : '-'}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={'bold'}>
            {round.isEnded
              ? t('inspection_realquantity_label')
              : t('requestedquantity_field_label')}
            :
          </Typography>
          <Typography>
            {round.realQuantity !== null ? round.realQuantity : '-'}
          </Typography>
          <Typography fontWeight={'bold'}>
            {t('koquantity_field_label')}:
          </Typography>
          <Typography>
            {round.koQuantity !== null ? round.koQuantity : '-'}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={'bold'}>
            {round.isEnded
              ? t('testinground_testingquanity_label')
              : t('testinground_remainingquantity_label')}
            :
          </Typography>
          <Typography>
            {round.revisionedQuantity !== null ? round.revisionedQuantity : '-'}
          </Typography>
          <Typography fontWeight={'bold'}>
            {t('failquantity_field_label')}:
          </Typography>
          <Typography>
            {round.blockedQuantity !== null ? round.blockedQuantity : '-'}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={!round.isEnded}
              onClick={handleReportClick}
              sx={{ minWidth: '140px' }}
            >
              {t('report_label')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={round.resourcesCount === 0}
              onClick={(): void => handleResourcesClick(round.id)}
              sx={{ minWidth: '140px' }}
            >
              {t('resources_label')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                (round.criticalNonCompliancesFound ?? 0) +
                  (round.majorNonCompliancesFound ?? 0) +
                  (round.minorNonCompliancesFound ?? 0) +
                  (round.notCountedNonCompliancesFound ?? 0) ===
                0
              }
              onClick={(): void => handleNonCompliancesClick(round.id)}
              sx={{ minWidth: '140px' }}
            >
              {t('defectsdetail_label')}
            </Button>
          </Box>
        </TableCell>
      </StyledTableRow>
      <DefectsDialog
        open={openDefectsDialog}
        onClose={(): void => setOpenDefectsDialog(false)}
        defects={defects}
      />
      <ResourcesDialog
        open={openResourcesDialog}
        onClose={(): void => setOpenResourcesDialog(false)}
        resources={resources}
      />
    </>
  );
}

const getLotStatusColor = (testingRoundList: LotRound[]): string => {
  const lastPosition: number = testingRoundList.length - 1;

  return testingRoundList.length === 1
    ? getRoundStatusColor(testingRoundList[lastPosition])
    : testingRoundList[lastPosition].isEnded ?
      testingRoundList[lastPosition].result ?
        '#34C759'
        : '#FF3B30'
      : '#FFCC00';
};

const getRoundStatusColor = (round: LotRound): string => {
  return round.isEnded
    ? round.result
      ? '#34C759'
      : '#FF3B30'
    : (round.acceptedQuantity ?? 0) +
          (round.standbyQuantity ?? 0) +
          (round.koQuantity ?? 0) +
          (round.blockedQuantity ?? 0) +
          (round.criticalNonCompliancesFound ?? 0) +
          (round.majorNonCompliancesFound ?? 0) +
          (round.minorNonCompliancesFound ?? 0) +
          (round.notCountedNonCompliancesFound ?? 0) +
          round.resourcesCount ===
        0
      ? brand[500]
      : '#FFCC00';
};

const getLotStatus = (testingRoundList: LotRound[]): string => {
  const lastPosition: number = testingRoundList.length - 1;

  return testingRoundList.length === 1
    ? getRoundStatus(testingRoundList[lastPosition])
    : testingRoundList[lastPosition].isEnded ?
      testingRoundList[lastPosition].result ?
        'status_completed_pass_label'
        : 'status_failed_label'
      : 'status_inprogress_label';
};

const getRoundStatus = (round: LotRound): string => {
  return round.isEnded
    ? round.result
      ? 'status_completed_pass_label'
      : 'status_failed_label'
    : (round.acceptedQuantity ?? 0) +
          (round.standbyQuantity ?? 0) +
          (round.koQuantity ?? 0) +
          (round.blockedQuantity ?? 0) +
          (round.criticalNonCompliancesFound ?? 0) +
          (round.majorNonCompliancesFound ?? 0) +
          (round.minorNonCompliancesFound ?? 0) +
          (round.notCountedNonCompliancesFound ?? 0) +
          round.resourcesCount ===
        0
      ? 'inspectionlist_todo_label'
      : 'status_inprogress_label';
};

export default OrderList;
