import { Box, CircularProgress } from '@mui/material';

import LoadingSpinnerStyle from './LoadingSpinnerStyle';

export default function LoadingSpinner(): JSX.Element {
  return (
    <Box sx={LoadingSpinnerStyle.LoadingSpinnerContainer()}>
      <CircularProgress />
    </Box>
  );
}
