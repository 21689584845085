import type { Auth } from '../../services/Auth/Auth';
import type { ColorMode } from '../../services/Theme/ThemeContext';
import type { SubmitHandler } from 'react-hook-form';
import type { NavigateFunction } from 'react-router-dom';

import { Box, Container } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as Logo } from '../../assets/images/qualichy_icon.svg';
import useAuth from '../../services/Auth/AuthService';
import { useColorMode } from '../../services/Theme/ThemeContext';

export interface FormValues {
  email: string;
  code: string;
  new_password: string;
}

function ResetPassword(): JSX.Element {
  const { t } = useTranslation();
  const theme: ColorMode = useColorMode();
  const auth: Auth = useAuth();
  const navigate: NavigateFunction = useNavigate();
  const { register, handleSubmit, formState: { errors, isValid } } = useForm<FormValues>({ mode: 'all' });
  const [searchParams] = useSearchParams();

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    try {
      await auth.submitForgotPassword(data.code, data.email, data.new_password);
      toast.success(t('forgotpassword_passwordchanged_label'));
      navigate('/login');
    } catch (err) {
      toast.error(t('error_forgotpassword_codemismatch_label'));
      console.log('err', err);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Logo style={{ marginBottom: '1rem', width: '40%', height:'40%', fill:theme.mode === 'light'? '#000000' : '#ffffff' }} />
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          {t('forgotpassword_resetpassword_label')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField fullWidth label={t('login_email_label')} placeholder={t('login_email_label')} autoComplete="email" value={decodeURIComponent(String(searchParams.get('email')))} variant="outlined" sx={{ my: 2 }} {...register('email', { required: true, value: decodeURIComponent(String(searchParams.get('email'))), pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })} helperText={errors.email?.type === 'required' ? t('error_requiredfield_helpertext') : errors.email?.type === 'pattern' ? t('error_login_invalidemail_helpertext') : ''} />
          <TextField fullWidth label={t('forgotpassword_resetcode_label')} placeholder={t('forgotpassword_resetcode_label')} autoFocus variant="outlined" sx={{ my: 2 }} {...register('code', { required: true })} helperText={errors.code?.type === 'required' ? t('error_requiredfield_helpertext') : ''} />
          <TextField fullWidth label={t('login_newpassword_label')} placeholder={t('login_newpassword_label')} type="password" variant="outlined" sx={{ my: 2 }} helperText={errors.new_password?.type === 'required' ? t('error_requiredfield_helpertext') : errors.new_password?.type === 'pattern' ? t('error_login_passwordpattern_label') : ''} {...register('new_password', { required: true, pattern: /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/ })} />
          <Button type="submit" sx={{ width: '100%', mt: 3 }} variant="contained" color="primary" disabled={!isValid}>{t('forgotpassword_changepassword_action')}</Button>
        </form>
      </Box>
    </Container>
  );
}

export default ResetPassword;
