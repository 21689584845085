import type {
  ReactElement,
  ReactNode } from 'react';

import React, {
  createContext,
  useContext,
  useState,
} from 'react';

interface LoadingSpinnerProps {
  children: ReactNode;
}

type LoadingContextType = [
  number,
  React.Dispatch<React.SetStateAction<number>>
];

const LoadingSpinnerContext: React.Context<LoadingContextType | null> = createContext<LoadingContextType | null>(null);

function LoadingSpinnerProvider({
  children,
}: LoadingSpinnerProps): ReactElement {
  const loadingState: [number, React.Dispatch<React.SetStateAction<number>>] = useState<number>(0);

  return (
    <LoadingSpinnerContext.Provider value={loadingState}>
      {children}
    </LoadingSpinnerContext.Provider>
  );
}

function useLoadingSpinnerContext(): LoadingContextType {
  const context: LoadingContextType | null = useContext(LoadingSpinnerContext);
  if (context === null) {
    throw new Error(
      'Please wrap this function into the LoadingSpinnerProvider',
    );
  }

  return context;
}

export { LoadingSpinnerProvider, useLoadingSpinnerContext };
