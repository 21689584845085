import type { Auth } from '../../services/Auth/Auth';
import type { User, UserInfo, UserRole } from '../../services/User/User';
import type { ErrorRespose } from '../../utils/response';
import type { Theme } from '@mui/material/styles';
import type { AxiosError, AxiosResponse } from 'axios';
import type { Dispatch, MouseEvent, SetStateAction } from 'react';
import type { NavigateFunction } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import pjson from '../../../package.json';

import logo from '../../assets/images/givenchy.svg';
import { get } from '../../services/Api/ApiFunctions';
import useAuth from '../../services/Auth/AuthService';
import { useUserContext } from '../../services/User/UserServiceProvider';

import UserInfoDialog from '../dialogs/UserInfoDialog/UserInfoDialog';

import ItemList from './ItemList';
import SwitchRoleSelect from './SwitchRole/SwitchRoleSelect';
import ToggleTheme from './ToggleTheme/ToggleTheme';

const drawerWidth: number = 240;

const AppBar: any = styled(MuiAppBar, {
  shouldForwardProp: (prop: any) => prop !== 'open',
})(({ theme, open }: { theme: Theme; open: boolean }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer: any = styled(MuiDrawer, {
  shouldForwardProp: (prop: any) => prop !== 'open',
})(({ theme, open }: { theme: Theme; open: boolean }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflow: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(6),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8),
      },
    }),
  },
}));

function Layout(): any {
  const auth: Auth = useAuth();
  const [user, setUser]: [User, Dispatch<SetStateAction<User>>] =
    useUserContext();
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openUserInfoDialog, setOpenUserInfoDialog] = useState(false);
  const [userRole, setUserRole] = useState<number | string>(user.activeRoleId);
  const [rolesList, setRolesList] = useState<UserRole[]>(user.roles);
  const toggleDrawer = (): void => {
    setOpen(!open);
  };
  const muiTheme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(muiTheme.breakpoints.down('sm'));

  // top-right user context menu
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openContextMenu: boolean = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async (): Promise<void> => {
    setAnchorEl(null);
    await auth.logout();
    auth.oAuthLogout();
    navigate('/login');
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleUserRole = (newRole: number | string): void => {
    setUserRole(newRole);
  };

  const handleUserInfo = (): void => {
    setAnchorEl(null);
    setOpenUserInfoDialog(true);
  };

  useEffect(() => {
    setRolesList(user.roles);
    setUserRole(user.activeRoleId);
  }, [user.roles]);

  useEffect(() => {
    get<UserInfo>('/users/me')
      .then((res: AxiosResponse<UserInfo, any> | undefined) => {
        if (res?.data !== undefined) {
          const active_role: UserRole | undefined = res.data.roles.find(
            (role: UserRole) => role.id === res.data.activeRoleId,
          );
          const userInfo: User = {
            ...user,
            ...res.data,
            active_role: active_role ?? null,
          };
          setUser(userInfo);
        }
      })
      .catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t('user_generic_error'));
      });
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <img width="30px" alt="givenchy logo" src={logo} />
          </Box>
          <Box sx={{ pr: 1 }}>
            <ToggleTheme />
          </Box>
          <SwitchRoleSelect
            setRole={handleUserRole}
            userRoleParent={userRole}
            sxProp={{
              mr: '16px',
              borderRadius: '16px',
              visibility: `${isMobile ? 'hidden' : 'visible'}`,
              height: '40px',
              minWidth: '250px',
            }}
            rolesListParent={rolesList}
          />
          {!isMobile && (
            <Typography>
              {user.name} {user.surname}
            </Typography>
          )}
          <IconButton
            color="inherit"
            onClick={handleClick}
            sx={{ ml: '16px' }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar>{`${user.name.charAt(0)}${user.surname.charAt(0)}`}</Avatar>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openContextMenu}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleUserInfo}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('usermenu_userinfo_label')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('usermenu_logout_label')}</ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          {open && (
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </Toolbar>
        <Divider />
        <List component="nav">
          <ItemList />
        </List>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mb: '20px',
          }}
        >
          <SwitchRoleSelect
            sxProp={{
              mb: '20px',
              borderRadius: '16px',
              backgroundColor: '#ffffff',
              visibility: `${!isMobile || !open ? 'hidden' : 'visible'}`,
              height: '40px',
            }}
            setRole={handleUserRole}
            userRoleParent={userRole}
            rolesListParent={rolesList}
          />
          <Typography
            sx={{ visibility: `${!open ? 'hidden' : 'visible'}` }}
          >{`version: ${pjson.version} - ${process.env.REACT_APP_STAGE?.charAt(0).toUpperCase() ?? ''}${process.env.REACT_APP_STAGE?.slice(1) ?? ''}`}</Typography>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme: Theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100%',
          maxWidth: '100%',
          marginTop: 8,
          overflow: 'hidden',
        }}
      >
        <Paper sx={{ height: 'calc(100vh - 64px)', overflowY: 'auto' }}>
          <Box sx={{ my: 6, mx: 3 }}>
            <Grid container gap={3}>
              {user.active_role != null && <Outlet />}
            </Grid>
          </Box>
        </Paper>
      </Box>
      <UserInfoDialog
        open={openUserInfoDialog}
        onClose={(): void => setOpenUserInfoDialog(false)}
      />
    </Box>
  );
}

export default Layout;
