// amplify-config.js.

import type { ResourcesConfig } from 'aws-amplify';

// -- AWS AMPLIFY CONFIGURATION PARAMETERS --

// Uncomment this to test env vars
// console.log('env', process.env);

const AmplifyConfig: ResourcesConfig = {
  // Existing Auth
  Auth: {
    Cognito: {
      userPoolClientId:
        process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID ?? '',
      userPoolId: process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLID ?? '',
    },
  },
};

export default AmplifyConfig;

