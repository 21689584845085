import type { User } from '../../services/User/User';
import type { Theme } from '@mui/material/styles';
import type { NavigateFunction } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import { useMediaQuery, useTheme } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useUser from '../../services/User/UserService';

function ItemList(): JSX.Element {
  const { t } = useTranslation();
  const user: User = useUser();
  const navigate: NavigateFunction = useNavigate();
  const muiTheme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(muiTheme.breakpoints.down('sm'));

  return (
    <>
      {user.active_role?.role !== undefined && (user.active_role.role === 'SUPPLIER' || user.active_role.role === 'COORDINATOR') &&
      (<ListItemButton sx={{ pl: `${isMobile ? '10px': '20px'}` }} onClick={(): void => navigate('/home')}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={t('menu_home_label')} />
      </ListItemButton>
      )}
      <ListItemButton sx={{ pl: `${isMobile ? '10px': '20px'}` }} onClick={(): void => navigate('/orders')}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary={t('orderslist_title')} />
      </ListItemButton>
    </>
  );
}

export default ItemList;
