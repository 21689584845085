import type { Auth } from '../../services/Auth/Auth';
import type { ColorMode } from '../../services/Theme/ThemeContext';
import type { ConfirmSignInOutput, SignInOutput } from 'aws-amplify/auth';
import type { SubmitHandler } from 'react-hook-form';
import type { NavigateFunction } from 'react-router-dom';

import { Box, Container, Divider, Link } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import pjson from '../../../package.json';

import okta_logo from '../../assets/images/okta_logo.svg';
import { ReactComponent as Logo } from '../../assets/images/qualichy_icon.svg';
import ForgotPasswordDialog from '../../components/dialogs/ForgotPasswordDialog/ForgotPasswordDialog';
import ToggleTheme from '../../components/Layout/ToggleTheme/ToggleTheme';
import useAuth from '../../services/Auth/AuthService';
import { useColorMode } from '../../services/Theme/ThemeContext';

export interface FormValues {
  email: string;
  password: string;
  new_password: string;
}

function Login(): JSX.Element {
  const { t } = useTranslation();
  const theme: ColorMode = useColorMode();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: 'all' });
  const auth: Auth = useAuth();
  const navigate: NavigateFunction = useNavigate();
  const [newPasswordChallenge, setNewPasswordChallenge] = useState(false);
  const [user, setUser] = useState<SignInOutput | null>(null);
  const [searchParams] = useSearchParams();
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (!newPasswordChallenge) {
      await login(data.email, data.password);
    } else {
      await confirmNewPassword(user, data.new_password, data.email);
    }
  };

  useEffect(() => {
    if (searchParams.has('code')) {
      auth.oAuthLogin(String(searchParams.get('code')));
    }
    if (searchParams.has('error_description')) {
      if (
        Boolean(searchParams.get('error_description')) &&
        (searchParams.get('error_description')?.includes('PreSignUp') ?? false)
      ) {
        setTimeout(() => {
          toast.error(t('error_login_alreadyregistered_label'));
          navigate('/login');
        }, 500);
      }
    }
  }, [searchParams]);

  const login = async (email: string, password: string): Promise<void> => {
    try {
      const cognitoUser: SignInOutput | undefined = await auth.login(
        email,
        password,
      );
      if (cognitoUser !== undefined) {
        setUser(cognitoUser);
        if (
          cognitoUser.nextStep.signInStep ===
          'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
        ) {
          setNewPasswordChallenge(true);
        } else {
          toast.success(t('login_success_label'));
        }
      }
    } catch (err) {
      toast.error(t('error_login_incorrectcredential_label'));
      console.log('err', err);
    }
  };

  const federatedLogin = (): void => {
    if (
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_URL != null &&
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_DOMAIN != null &&
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_REDIRECT_URL != null &&
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID != null
    ) {
      const url: string =
        process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_URL.replace(
          'DOMAIN',
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_DOMAIN,
        )
          .replace(
            'IDENTITY_PROVIDER',
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_PROVIDER ?? '',
          )
          .replace(
            'REDIRECT_URL',
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_REDIRECT_URL,
          )
          .replace(
            'USERPOOLWEBCLIENTID',
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID,
          );
      window.location.href = url;
    }
  };

  const confirmNewPassword = async (
    cognitoUser: SignInOutput | null,
    newPassword: string,
    email: string,
  ): Promise<void> => {
    const response: ConfirmSignInOutput | undefined =
      await auth.confirmNewPassword(cognitoUser, newPassword);
    if (response !== undefined) {
      await login(email, newPassword);
    }
  };

  const handleClose = async (returnValue: string): Promise<void> => {
    setOpenForgotPasswordDialog(false);
    if (returnValue !== 'closed') {
      try {
        await auth.forgotPasswordRequest(returnValue);
        toast.success(t('forgotpassword_codesent_label'));
        navigate(`/reset-password?email=${encodeURIComponent(returnValue)}`);
      } catch (err) {
        toast.error(t('error_forgotpassword_codesent_label'));
        console.log('err', err);
      }
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Logo style={{ marginBottom: '1rem', width: '40%', height:'40%', fill:theme.mode === 'light'? '#000000' : '#ffffff' }} />
        <Box sx={{ display: 'flex', textAlign:'center', pr:'40px' }}>
          <ToggleTheme />
          <Typography alignContent='center' component="h1" variant="h5">
            {t('login_signin_label')}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            fullWidth
            autoFocus
            label={t('login_email_label')}
            placeholder={t('login_email_label')}
            disabled={newPasswordChallenge}
            autoComplete="email"
            variant="outlined"
            sx={{ my: 2 }}
            {...register('email', {
              required: true,
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            })}
            helperText={
              errors.email?.type === 'required'
                ? t('error_requiredfield_helpertext')
                : errors.email?.type === 'pattern'
                  ? t('error_login_invalidemail_helpertext')
                  : ''
            }
          />
          <TextField
            fullWidth
            label={t('login_password_label')}
            placeholder={t('login_password_label')}
            disabled={newPasswordChallenge}
            autoComplete="password"
            type="password"
            variant="outlined"
            sx={{ my: 2 }}
            {...register('password', { required: true })}
            helperText={
              errors.password?.type === 'required'
                ? t('error_requiredfield_helpertext')
                : ''
            }
          />
          {newPasswordChallenge ? (
            <TextField
              fullWidth
              label={t('login_newpassword_label')}
              placeholder={t('login_newpassword_label')}
              type="password"
              variant="outlined"
              sx={{ my: 2 }}
              helperText={
                errors.new_password?.type === 'required'
                  ? t('error_requiredfield_helpertext')
                  : errors.new_password?.type === 'pattern'
                    ? t('error_login_passwordpattern_label')
                    : ''
              }
              {...register('new_password', {
                required: true,
                pattern:
                  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
              })}
            />
          ) : null}
          <Link
            sx={{ cursor: 'pointer' }}
            variant="body2"
            onClick={(): void => setOpenForgotPasswordDialog(true)}
          >
            {t('login_forgotpassword_action')}
          </Link>
          {!newPasswordChallenge ? (
            <Button
              type="submit"
              sx={{ width: '100%', mt: 3 }}
              variant="contained"
              color="primary"
              disabled={!isValid}
            >
              {t('login_actionbutton_login')}
            </Button>
          ) : null}
          {newPasswordChallenge ? (
            <Button
              type="submit"
              sx={{ width: '100%', mt: 3 }}
              variant="contained"
              color="primary"
              disabled={!isValid}
            >
              {t('login_confirmpassword_action')}
            </Button>
          ) : null}
        </form>
      </Box>
      <Divider sx={{ my: 6 }}>
        <Typography variant="caption">{t('login_or_label')}</Typography>
      </Divider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          variant="contained"
          className="google-button-hover"
          sx={{
            backgroundColor: '#336699',
          }}
          onClick={(): void => federatedLogin()}
        >
          <img
            style={{ width: '100%', height: '90%' }}
            alt="givenchy logo"
            src={okta_logo}
          />
        </Button>
      </Box>
      <ForgotPasswordDialog
        open={openForgotPasswordDialog}
        onClose={handleClose}
      />
      <Box sx={{ mt: 5, textAlign: 'center' }}>
        <Typography>{`version: ${pjson.version} ${process.env.REACT_APP_STAGE !== 'production' ? ` - ${process.env.REACT_APP_STAGE?.charAt(0).toUpperCase() ?? ''}${process.env.REACT_APP_STAGE?.slice(1) ?? ''}` : ''}`}</Typography>
      </Box>
    </Container>
  );
}

export default Login;
