export default function storageType(storage: string | null): Storage {
  if (storage === null) {
    throw new Error('ERROR');
  }
  switch (storage) {
    case 'local':
      return localStorage;
    case 'session':
      return sessionStorage;
    default:
      // use sessionStorage if not selected anything
      return sessionStorage;
  }
}
