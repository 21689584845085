import type { User } from '../../../services/User/User';
import type { Dayjs } from 'dayjs';
import type { UseFormSetValue } from 'react-hook-form';

import LockIcon from '@mui/icons-material/Lock';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import useUser from '../../../services/User/UserService';
import { gray } from '../../../theme/theme';

interface InspectionDataResponse {
  date: string
  department_id: number,
  id: number,
  inspector_id: number,
  is_locked: boolean,
  last_edit_date: string,
  report_url: string | null,
  scenario_id: number,
  signature_url: string |null,
  supplier_id: number,
}

interface SupplierScheduleInfo {
  count: number,
  overflow: boolean,
  sum: number
}

interface ScheduleConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  inspections?: InspectionDataResponse[] ;
  scheduleInfo?: SupplierScheduleInfo ;
  scheduledDate?: Dayjs | null;
  fetchTable: () => void;
  onSubmitConfirmDialog: (date?: string, isDateNotMerge?: boolean) => void;
  setTableValue?: UseFormSetValue<TableFormValues>
}

interface TableFormValues {
  name: string;
  surname: string;
  email: string;
  quantity: number;
  date: Dayjs | null;
  time: Dayjs | null;
  orderitemId?: number;
  typeId?: number;
  subtypeId?: string;
  inspectorId: string;
}

export interface FormValues {
  date: string | undefined;
}

function ScheduleConfirmDialog({
  open,
  onClose,
  inspections,
  scheduleInfo,
  scheduledDate,
  onSubmitConfirmDialog,
  setTableValue,
}: ScheduleConfirmDialogProps): JSX.Element {
  const { t } = useTranslation();
  const user: User = useUser();

  const onSubmit = (): void => {
    onSubmitConfirmDialog(undefined , true);
    onClose();
  };
  const onMergeSubmit = (formValues: FormValues): void => {
    onSubmitConfirmDialog(formValues.date);
    if(setTableValue != null) {
      setTableValue('date', dayjs(formValues.date));
      setTableValue('time', dayjs(formValues.date));
    }
    onClose();
  };
  const { register, handleSubmit, watch, setValue } = useForm<FormValues>({ mode: 'all' });

  return (
    <Dialog open={open} onClose={(): void => {
      setValue('date', undefined); onClose();
    }} maxWidth='md'>
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography fontWeight='bold' typography='h5' sx={{ pt: 1 }}>{t('schedule_confirm')}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <form>
          {user.active_role?.role === 'SUPPLIER' && (
            <Box sx={{ display: 'flex', flexDirection:'column', gap: 2 }}>
              <Typography>
                <Trans
                  i18nKey="confirm_schedule_message"
                  values={{ '1': String(scheduleInfo?.count), '2': String(scheduleInfo?.sum) }}
                />
              </Typography>
              <Typography>{t('continue_schedule_message')}</Typography>
            </Box>
          )}
          {user.active_role?.role === 'COORDINATOR' && (
            <Box sx={{ display: 'flex', flexDirection:'column', gap: 2 }}>
              <Typography>{t('inspectionsalreadypresent_schedule_dialog')}</Typography>
              <Typography fontStyle={'italic'}>{t('wanttomerge_schedule_message')}</Typography>
              <Typography fontWeight={'bold'}>{t('infomergeinspection_schedule_message')}</Typography>
              <FormControl>
                <RadioGroup
                  name="date">
                  {Array.isArray(inspections) && inspections.length !== 0 && inspections.map((inspection: InspectionDataResponse, index: number) =>
                    <FormControlLabel
                      {...register('date')}
                      disabled={inspection.is_locked || inspection.report_url !== null}
                      key={index}
                      label={
                        <Box sx={{ display:'flex', flexDirection:'row', alignItems:'center', gap:1 }}>
                          <Typography color={`${inspection.is_locked ? gray[400] : ''}`}>
                            {dayjs(inspection.date).utc().local().format('DD/MM/YYYY')}, {dayjs(inspection.date).utc().local().format('HH:mm')}
                          </Typography>
                          {inspection.is_locked && <LockIcon sx={{ color:gray[400] }} />}
                        </Box>
                      }
                      value={dayjs(inspection.date).format()}
                      control={<Radio/>}
                    />,
                  )}
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        {user.active_role?.role === 'SUPPLIER' && (
          <>
            <Button variant='contained' color='primary' onClick={onSubmit}>{t('confirm_schedule_action')}</Button>
            <Button variant='contained' color='error' onClick={(): void => onClose()}>{t('close_schedule_action')}</Button>
          </>
        )}
        {user.active_role?.role === 'COORDINATOR' && (
          <>
            <Button
              variant='contained'
              color='primary'
              disabled={watch().date === undefined}
              onClick={handleSubmit((formValues: FormValues)=>
                onMergeSubmit(formValues),
              )}
            >{t('confirm_schedule_action')}</Button>
            <Button
              variant='contained'
              color='primary'
              disabled={Array.isArray(inspections) && inspections.some((inspection: InspectionDataResponse)=> dayjs(inspection.date).format() === dayjs(scheduledDate).format())}
              onClick={onSubmit}
            >{t('schedule_confirm_not_merge_action')}</Button>
            <Button variant='contained' color='error' onClick={(): void => {
              setValue('date', undefined);
              onClose();
            }}>{t('close_schedule_action')}</Button>
          </>
        )}
      </DialogActions>
    </Dialog >
  );
}

export default ScheduleConfirmDialog;
