 interface UserRole {
  id: number,
  department: CodeId,
  scenario: CodeId,
  role: string,
  country: string
  supplier: {
    code: string,
    id: number,
    name: string,
    country: string
  } | null,
  wantsReceiveEmail: boolean,
  isRoot: boolean,
}

interface CodeId {
  code: string,
  id: number
}

interface UserInfo {
  name: string;
  surname: string;
  email: string;
  language: string;
  activeRoleId: number | string;
  roles: UserRole[]
}

interface User {
  name: string;
  surname: string;
  email: string;
  language: string;
  activeRoleId: number | string;
  active_role: UserRole | null;
  roles: UserRole[];
  isUserFederated: boolean,
  logout: () => void;
}

interface OrderedRoles {
  departments: OrderedDepartments[] | null;
}

interface OrderedDepartments {
  code: string,
  id: number,
  scenarios: OrderedScenarios[]
}

interface OrderedScenarios {
  code: string,
  id: number,
  roles: OrderedRole[]
}

interface OrderedRole {
  code: string,
  id: number,
  supplier: {
    code: string,
    id: number,
    name: string
  } | null,
}

const userInitialState: User = {
  name: '',
  surname: '',
  email: '',
  language: '',
  activeRoleId: '',
  active_role: null,
  roles: [],
  isUserFederated: false,
  logout: () => {
    throw new Error('Function not implemented.');
  },
};

export type { UserRole, UserInfo, User, OrderedRoles, OrderedDepartments, OrderedScenarios,OrderedRole };
export { userInitialState };
