import type { User, UserRole } from '../../../services/User/User';
import type { ErrorRespose } from '../../../utils/response';
import type { AxiosError, AxiosResponse } from 'axios';
import type { ControllerRenderProps, SubmitHandler } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { type Dispatch, type SetStateAction, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { put } from '../../../services/Api/ApiFunctions';
import { useUserContext } from '../../../services/User/UserServiceProvider';
import { languagesList } from '../../../utils/constants';

interface UserInfoDialogProps {
  open: boolean;
  onClose: () => void;
}

interface Roles {
  id: number,
  wantsReceiveEmail: boolean,
  isRoot: boolean
}

interface FormValues {
  name: string,
  surname: string
  email: string;
  language: string,
  rolesConfig: Roles[]
}

function UserInfoDialog({ open, onClose }: UserInfoDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [user, setUser]: [User, Dispatch<SetStateAction<User>>] = useUserContext();
  const { register, control, handleSubmit, setValue, watch, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'all',
    values: {
      name: user.name,
      surname: user.surname,
      email: user.email,
      language: user.language,
      rolesConfig: user.roles.map((role: UserRole) => {
        return { id: role.id, wantsReceiveEmail: role.wantsReceiveEmail, isRoot: role.isRoot };
      }),
    },
  });

  useEffect(()=> {
    setValue('name', user.name);
    setValue('surname', user.surname);
    setValue('email', user.email);
    setValue('language', user.language);
    setValue('rolesConfig', user.roles.map((role: UserRole) => {
      return { id: role.id, wantsReceiveEmail: role.wantsReceiveEmail, isRoot: role.isRoot };
    }));
  },[user, open]);

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    put('/users/me', data)
      .then((res: AxiosResponse | undefined): PromiseLike<void> | void => {
        const userValue: User = { ...res?.data as User };
        setUser({ ...user, ...userValue } as User);
        toast.success(t('userinfo_update_label'));
      })
      .catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
    onClose();
  };

  return (
    <Dialog open={open} onClose={(): void => onClose()} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography fontWeight='bold' typography='h5' sx={{ pt: 1 }}>{t('userinfo_label')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: 'right' }} onClick={(): void => onClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField {...register('name', { required: true })} helperText={errors.name?.type === 'required' ? t('error_requiredfield_helpertext') : ''} fullWidth variant="outlined" placeholder={t('forgotpassword_recoveremail_label')} label={t('userinfo_name_label')} autoComplete="name" sx={{ my: 2 }} />
          <TextField {...register('surname', { required: true })} helperText={errors.surname?.type === 'required' ? t('error_requiredfield_helpertext') : ''} fullWidth variant="outlined" placeholder={t('forgotpassword_recoveremail_label')} label={t('userinfo_surname_label')} autoComplete="surname" sx={{ my: 2 }} />
          <TextField disabled={!user.isUserFederated} {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })} helperText={errors.email?.type === 'required' ? t('error_requiredfield_helpertext') : ''} fullWidth variant="outlined" placeholder={t('forgotpassword_recoveremail_label')} label={t('userinfo_role_email_label')} autoComplete="email" sx={{ my: 2 }} />
          <FormControl fullWidth sx={{ my: 2 }}>
            <Controller
              name="language"
              control={control}
              defaultValue=''
              render={({ field }: { field: ControllerRenderProps<FormValues, 'language'> }): JSX.Element => (
                <TextField
                  {...field}
                  select
                  variant="outlined"
                  label={t('userinfo_language_label')}
                  {...register('language', { required: true })}
                >
                  {languagesList.map((language: string) => {
                    return (
                      <MenuItem value={language} key={language}>
                        {t(`language_${language.slice(0,2)}`)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
          </FormControl>
          {user.roles.map((role: UserRole, idx: number) =>
            <Accordion key={role.id} sx={{ boxShadow: 2, mb:'10px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
              >
                <Typography>
                  {`${t(`${role.department.code.toLowerCase()}_label`)} > ${t(`${role.scenario.code.toLowerCase()}_label`)} > ${t(`${role.role.toLowerCase()}_label`)} ${role.supplier != null ? `> ${role.supplier.name}` : ''}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel control={<Checkbox checked={watch(`rolesConfig.${idx}.wantsReceiveEmail`)} {...register(`rolesConfig.${idx}.wantsReceiveEmail`)}/>} label={t('userinfo_role_email_label')} />
              </AccordionDetails>
            </Accordion>)}
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant='contained' color='error' onClick={(): void => onClose()}>{t('alert_actionbutton_cancel')}</Button>
        <Button variant='contained' color='primary' disabled={!isValid} onClick={handleSubmit(onSubmit)}>{t('forgotpassword_sendrequest_action')}</Button>

      </DialogActions>
    </Dialog >
  );
}

export default UserInfoDialog;
