import type { ErrorRespose } from '../../../utils/response';
import type { AxiosError } from 'axios';
import type { Dayjs } from 'dayjs';
import type { BaseSyntheticEvent } from 'react';
import type { ControllerFieldState, ControllerRenderProps, SubmitHandler } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { get } from '../../../services/Api/ApiFunctions';

interface ReportDialogProps {
  open: boolean;
  onClose: () => void;
}

interface EmailFormValues {
  from: Dayjs | null;
  to: Dayjs | null;
  }

function ReportDialog({ open, onClose }: ReportDialogProps): JSX.Element {
  const { t } = useTranslation();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { isValid: isValidEmail, errors },
  } = useForm<EmailFormValues>({
    mode: 'all',
  });

  const onSubmitEmail: SubmitHandler<EmailFormValues> = (formData: EmailFormValues, event?: BaseSyntheticEvent) => {
    const queryParams: URLSearchParams = new URLSearchParams();
    queryParams.set('from', dayjs(formData.from).startOf('date').format());
    queryParams.set('to', dayjs(formData.to).startOf('date').format());

    get<string>('/inspections/scheduled', queryParams)
      .then((): void => {
        toast.success(t('send_report_success'));
        onClose();
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
    event?.preventDefault();
  };

  return (
    <>
      <Dialog open={open} onClose={(): void => onClose()} maxWidth='xs' fullWidth>
        <DialogTitle>
          <Grid container>
            <Grid item xs={10}>
              <Typography fontWeight='bold' typography='h5' sx={{ pt: 1 }}>{t('email_requestsmadefrom_label')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton sx={{ float: 'right' }} onClick={(): void => onClose()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ px: 0 }}>
          <Box sx={{ py: 2, px: 3 }}>
            <form>
              <Box sx={{ display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center', gap: 2, flexWrap:'wrap' }}>
                <FormControl>
                  <Controller
                    name="from"
                    defaultValue={dayjs().add(-7,'day')}
                    control={control}
                    rules={{
                      required: true,
                      validate: (value: Dayjs | null) => value != null ? dayjs(watch('to')).startOf('date').format() >= value.startOf('date').format() : true,
                    }}
                    render={({ field, fieldState: { error, invalid } }: {field: ControllerRenderProps<EmailFormValues, 'from'>, fieldState: ControllerFieldState}): JSX.Element => (
                      <DatePicker
                        {...field}
                        format='DD/MM/YYYY'
                        {...register('from', { required: true })}
                        onChange={(val: Dayjs | null): void => field.onChange(val)}
                        slotProps={{ textField: {
                          label: t('email_from_label'),
                          error: (error != null || errors.from != null || invalid),
                          helperText: ((error?.type === 'required' || errors.from?.type === 'required') && t('error_requiredfield_helpertext') || (error?.type === 'validate' || errors.from?.type === 'validate') && t('date_not_greater_than_that_into_round')),
                        } }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Controller
                    name="to"
                    defaultValue={dayjs().add(1,'day')}
                    control={control}
                    rules={{
                      required: true,
                      validate: (value: Dayjs | null) => value != null ? dayjs(watch('from')).startOf('date').format() <= value.startOf('date').format() : true,
                    }}
                    render={({ field, fieldState: { error, invalid } }: {field: ControllerRenderProps<EmailFormValues, 'to'>, fieldState: ControllerFieldState}): JSX.Element => (
                      <DatePicker
                        {...field}
                        format='DD/MM/YYYY'
                        {...register('to', { required: true })}
                        onChange={(val: Dayjs | null): void => field.onChange(val)}
                        slotProps={{ textField: {
                          label: t('email_to_label'),
                          error: (error != null || errors.to != null || invalid),
                          helperText: ((error?.type === 'required' || errors.to?.type === 'required') && t('error_requiredfield_helpertext') || (error?.type === 'validate' || errors.to?.type === 'validate') && t('date_not_greater_than_that_into_round')),
                        } }}

                      />
                    )}
                  />
                </FormControl>
              </Box>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <>
            <Button sx={{ ml: 'auto' }} type="submit" variant="contained" disabled={!isValidEmail} onClick={handleSubmit(onSubmitEmail)}>{t('send_email_action')}</Button>
            <Button variant='contained' color='error' onClick={(): void => {
              onClose();
            }}>{t('close_schedule_action')}</Button>
          </>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ReportDialog;
