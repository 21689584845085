import type Loading from '../LoadingSpinner/Loading';
import type { AxiosResponse } from 'axios';

import axios from 'axios';
import { useEffect, useState } from 'react';

import useLoadingSpinnerContext from '../LoadingSpinner/LoadingSpinnerService';

export default function ResponseInterceptor(): null {
  const [isActive, setIsActive] = useState<boolean>(false);
  const loadingSpinner: Loading = useLoadingSpinnerContext();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const initializeInterceptor = () => {
    setIsActive(true);

    return axios.interceptors.response.use(
      // intercept response OK
      async (response: AxiosResponse) => {
        if (!((response.config.url?.includes('givenchy-quality-platform-webapp')) ?? false)) {
          loadingSpinner.addResponse();
        }

        // execute http api call
        return Promise.resolve(response);
      },
      async (error: any) => {
        // intercept response KO
        if (!error.config.url?.includes('givenchy-quality-platform-webapp')) {
          loadingSpinner.addResponse();
        }

        return Promise.reject(error);
      },
    );
  };

  useEffect(() => {
    if (!isActive) {
      initializeInterceptor();
    }
  }, []);

  return null;
}
