
import type Loading from '../../services/LoadingSpinner/Loading';
import type { User } from '../../services/User/User';
import type { ErrorRespose, OrderResponse } from '../../utils/response';
import type { AxiosError, AxiosResponse } from 'axios';
import type { ControllerRenderProps, SubmitHandler } from 'react-hook-form';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { type BaseSyntheticEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import OrderList from '../../components/lists/OrderList';
import { get } from '../../services/Api/ApiFunctions';
import useLoadingSpinnerContext from '../../services/LoadingSpinner/LoadingSpinnerService';
import useUser from '../../services/User/UserService';
import { checkDownloadPdf } from '../../utils/checkDownloadPdf';

interface FormValues {
  orderitemNumber: string,
  skuCode: string,
  supplierId: string,
  onlyUnfinishedOrderItems: string,
}

interface SupplierSelect {
  id: number,
  name: string
}

function Orders(): JSX.Element {
  const user: User = useUser();
  const { t } = useTranslation();
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const [orders, setOrders] = useState<OrderResponse | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [suppliers, setSuppliers] = useState<SupplierSelect[]>([]);
  const [orderPage, setOrderPage] = useState<number>(parseFloat((searchParams.get('page') ?? '0')));
  const { register, reset, control, watch, handleSubmit, getValues, formState: { isValid } } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      orderitemNumber: '',
      skuCode: '',
      supplierId: '',
      onlyUnfinishedOrderItems: '',
    },
    values: {
      orderitemNumber: searchParams.get('orderitemNumber') ?? '',
      skuCode: searchParams.get('skuCode') ?? '',
      supplierId: searchParams.get('supplierId') ?? '',
      onlyUnfinishedOrderItems: searchParams.get('onlyUnfinishedOrderItems') ?? '',
    },
  });

  const handleFilters = (): URLSearchParams => {
    const filters: URLSearchParams = new URLSearchParams();
    const formValues: FormValues = getValues();

    filters.set('page', orderPage.toString());
    filters.set('start', (10 * orderPage).toString());
    filters.set('onlyUnfinishedOrderItems', '');

    for(const key in formValues) {
      if(formValues[key as keyof typeof formValues] !== '') {
        filters.set(key, formValues[key as keyof typeof formValues]);
      }
    }
    if(user.active_role?.role === 'SUPPLIER') {
      filters.set('supplierId', user.active_role.supplier !== null ? user.active_role.supplier.id.toString(): '');
    }

    return filters;
  };

  const handleFetchOrders = (): void => {
    const filters: URLSearchParams = handleFilters();
    get<OrderResponse>('/orderitems', filters)
      .then((res: AxiosResponse<OrderResponse, any> | undefined): void => {
        if(res?.data !== undefined) {
          setOrders({ ...res.data });
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };

  const onSubmitFilter: SubmitHandler<FormValues> = (formData: FormValues, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    const previewSearchParams: URLSearchParams = searchParams;
    for(const key in formData) {
      if(formData[key as keyof typeof formData] !== '') {
        previewSearchParams.set(key, formData[key as keyof typeof formData]);
        setSearchParams(previewSearchParams);
      }
    }
    handleFetchOrders();
  };

  const onReset = (): void => {
    const formTableValues: FormValues = getValues();
    const previewSearchParams: URLSearchParams = searchParams;
    reset();
    for(const key in formTableValues) {
      previewSearchParams.delete(key);
    }
    setSearchParams(previewSearchParams);
    handleFetchOrders();
  };

  const handleFetchLists = (): void => {
    handleFetchOrders();
  };

  useEffect(() => {
    loadingSpinner.addRequest();
    handleFetchOrders();
    loadingSpinner.addResponse();
  }, [searchParams, orderPage, user.activeRoleId]);

  useEffect(() => {
    loadingSpinner.addRequest();
    get<SupplierSelect[]>('/suppliers')
      .then((res: AxiosResponse<SupplierSelect[], any> | undefined) => {
        if(res?.data !== undefined) {
          setSuppliers(res.data);
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
    loadingSpinner.addResponse();
  },[user.activeRoleId]);

  useEffect(() => {
    checkDownloadPdf();
  },[]);

  return(
    <>
      <Typography variant='h4'>{t('orderslist_title')}</Typography>
      <Accordion sx={{ boxShadow: 2, borderRadius:'10px', width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content">
          <Box sx={{ display:'flex', gap:1, alignItems:'center' }}>
            <FilterListIcon/>
            <Typography fontWeight='bold'>{t('filters_label')}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ py: 2, px: 3, width: '100%' }}>
            <form>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Controller
                    render={({ field }: {field: ControllerRenderProps<FormValues, 'orderitemNumber'>}): JSX.Element =>
                      <TextField {...field} variant="outlined" placeholder={t('ordercode_label')} label={t('ordercode_label')} />}
                    name="orderitemNumber"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Controller
                    render={({ field }: {field: ControllerRenderProps<FormValues, 'skuCode'>}): JSX.Element =>
                      <TextField {...field} variant="outlined" placeholder={t('modelcode_label')} label={t('modelcode_label')} />}
                    name="skuCode"
                    control={control}
                  />
                </Grid>
                {user.active_role?.role === 'COORDINATOR' &&
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl>
                      <Controller
                        name="supplierId"
                        control={control}
                        defaultValue=''
                        render={({ field }: {field: ControllerRenderProps<FormValues, 'supplierId'>}): JSX.Element => (
                          <TextField
                            {...field}
                            select
                            variant="outlined"
                            label={t('addvisit_supplier_title')}
                            {...register('supplierId')}
                          >
                            <MenuItem key={-1}></MenuItem>
                            {suppliers.map((supplier: SupplierSelect) => {
                              return (
                                <MenuItem value={supplier.id} key={supplier.id}>
                                  {`${supplier.name}`}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        )}
                      />
                    </FormControl>
                  </Grid>
                }
                <Grid item xs={12} md={6} lg={4} xl={3} sx={{ display:'flex', alignContent:'center' }}>
                  <Controller
                    render={({ field }: {field: ControllerRenderProps<FormValues, 'onlyUnfinishedOrderItems'>}): JSX.Element =>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            {...register('onlyUnfinishedOrderItems')}
                            checked={Boolean(watch().onlyUnfinishedOrderItems)}
                          />} label={t('unfinished_label')} />
                    }
                    name="onlyUnfinishedOrderItems"
                    control={control}
                  />
                </Grid>
                <Grid item sx={{ ml: 'auto', alignContent:'center' }}>
                  <Button type="submit" sx={{ mr: 3 }} variant="contained" color='primary' disabled={!isValid} onClick={handleSubmit(onSubmitFilter)}>{t('filter_action')}</Button>
                  <Button type='reset' color='error' variant="contained" onClick={onReset}>{t('remove_filter_action')}</Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ width:'100%', justifyContent: 'center', textAlign: 'center' }}>
        {
          orders !== undefined && orders.items.length !== 0 ?
            <OrderList
              orderListResponse={orders}
              pageNumber={orderPage}
              setPageNumber={setOrderPage}
              fetchData={handleFetchLists} /> :
            <Typography variant="h3" align='center' color='#8f9bb3'>{t('dashboard_orderitemlist_empty_label')}</Typography>
        }
      </Box>
    </>);
}

export default Orders;
