import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import axios from 'axios';

export async function get<T>(
  url: string,
  params?: URLSearchParams,
  config: AxiosRequestConfig = {},
): Promise<AxiosResponse<T, any> | undefined> {
  if (params != null) {
    const keysToDelete: string[] = [];
    params.forEach((value: string, key: string) => {
      if ((value.length === 0) || value === 'undefined') {
        keysToDelete.push(key);
      }
    });
    for (const keyToDelete of keysToDelete) {
      params.delete(keyToDelete);
    }
  }
  if (process.env.REACT_APP_ENDPOINT_ROOTAPI != null) {
    return axios.get<T>(`${process.env.REACT_APP_ENDPOINT_ROOTAPI}${url}`, {
      params,
      ...config,
    });
  }
}

export async function getLocalization<T>(
  url: string,
  params?: URLSearchParams,
  config: AxiosRequestConfig = {},
): Promise<AxiosResponse<T, any> | undefined> {
  if (params != null) {
    const keysToDelete: string[] = [];
    params.forEach((value: string, key: string) => {
      if ((value.length === 0) || value === 'undefined') {
        keysToDelete.push(key);
      }
    });
    for (const keyToDelete of keysToDelete) {
      params.delete(keyToDelete);
    }
  }
  if (process.env.REACT_APP_TRAPP_ENDPOINT_ROOTAPI != null) {
    return axios.get<T>(`${process.env.REACT_APP_TRAPP_ENDPOINT_ROOTAPI}${url}`, {
      params,
      ...config,
      headers:{
        'x-api-key': `${process.env.REACT_APP_TRAPP_API_KEY ?? ''}`,
      },
    });
  }
}

export async function put<T>(
  url: string,
  data?: T,
  params?: URLSearchParams,
): Promise<AxiosResponse<any, any> | undefined> {
  if (process.env.REACT_APP_ENDPOINT_ROOTAPI != null) {
    return axios.put(process.env.REACT_APP_ENDPOINT_ROOTAPI + url, data, {
      params,
    });
  }
}

export async function post<T>(
  url: string,
  data: T,
  params?: URLSearchParams,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<any, any> | undefined> {
  if (process.env.REACT_APP_ENDPOINT_ROOTAPI != null) {
    return axios.post(process.env.REACT_APP_ENDPOINT_ROOTAPI + url, data, {
      params,
      ...config,
    });
  }
}

export async function deleteData(
  url: string,
  params?: URLSearchParams,
  config: AxiosRequestConfig = {},
): Promise<AxiosResponse<any, any> | undefined> {
  if (process.env.REACT_APP_ENDPOINT_ROOTAPI != null) {
    return axios.delete(process.env.REACT_APP_ENDPOINT_ROOTAPI + url, {
      params,
      ...config,
    });
  }
}
