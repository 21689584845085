import type { UserAttributes } from './Auth';
import type { Auth } from './Auth';
import type {
  AuthSession,
  AuthUser,
  ConfirmSignInOutput,
  FetchUserAttributesOutput,
  JWT,
  ResetPasswordOutput,
} from 'aws-amplify/auth';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import { CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import {
  type SignInOutput,
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  updatePassword,
} from 'aws-amplify/auth';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { isTokenExpired } from '../../utils/helpers';
import storageType from '../../utils/storageService';

import { useAuthContext } from './AuthServiceProvider';

const AuthService = (): Auth => {
  const [auth, setAuth] = useAuthContext();
  const { t } = useTranslation();

  const hasToken: string | null = storageType(
    process.env.REACT_APP_STORAGE ?? '',
  ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`);

  const userAttributesString: string | null = storageType(
    process.env.REACT_APP_STORAGE ?? '',
  ).getItem(`${process.env.REACT_APP_NAME ?? ''}-logged-user`);

  return {
    isLoggedIn: !!hasToken,
    userAttributes: (userAttributesString != null)
      ? JSON.parse(userAttributesString)
      : null,

    async login(username, password): Promise<SignInOutput | undefined> {
      try {
        const userResult: SignInOutput = await signIn({ username, password });
        if (userResult.nextStep.signInStep !== 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          this.setIsLoggedIn(true);
        }

        return userResult;
      } catch (err) {
        toast.error(t('user_generic_error'));
      }
    },
    async getToken(): Promise<string> {
      try {
        const token: string | undefined = await this.oAuthTokenRefresh();
        if (token != null) {
          return token;
        }

        return '';
      } catch {
        storageType(
          process.env.REACT_APP_STORAGE ?? 'local',
        ).removeItem('givenchy-quality-platform-webapp-token');
        window.location.href = '/login';

        return '';
      }
    },
    oAuthLogin(code: string): void {
      try {
        const body: URLSearchParams = new URLSearchParams();
        const options: {
          headers: {
              'Content-Type': string;
          };
        } = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        body.set('code', code);
        if (
          (process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_GRANT_TYPE != null) &&
          (process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID != null) &&
          (process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_REDIRECT_URL != null) &&
          (process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_OAUTH_TOKEN_URL != null)
        ) {
          body.set(
            'grant_type',
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_GRANT_TYPE,
          );
          body.set(
            'client_id',
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID,
          );
          body.set(
            'redirect_uri',
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_REDIRECT_URL,
          );
          axios
            .post(
              process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_OAUTH_TOKEN_URL,
              body.toString(),
              options,
            )
            .then((response: any) => {
              const session: CognitoUserSession = new CognitoUserSession({
                AccessToken: new CognitoAccessToken({
                  AccessToken: response.data.access_token,
                }),
                IdToken: new CognitoIdToken({
                  IdToken: response.data.id_token,
                }),
                RefreshToken: new CognitoRefreshToken({
                  RefreshToken: response.data.refresh_token,
                }),
              });

              if (session.isValid()) {
                const token: string = session.getIdToken().getJwtToken();
                const accessToken: string = session.getAccessToken().getJwtToken();
                const refreshToken: string = session.getRefreshToken().getToken();
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : 'local',
                ).setItem(`${process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : ''}-token`, token);
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : 'local',
                ).setItem(
                  `${process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : ''}-access-token`,
                  accessToken,
                );
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : 'local',
                ).setItem(
                  `${process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : ''}-refresh-token`,
                  refreshToken,
                );
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : 'local',
                ).setItem('method', 'third-party');
              }

              this.setIsLoggedIn(true);
            })
            .catch(() => {
              toast.error(t('user_generic_error'));
            });
        }
      } catch {
        toast.error(t('user_generic_error'));
      }
    },
    async oAuthTokenRefresh(): Promise<string | undefined> {
      try {
        const body: Record<string, string> | string[][] | URLSearchParams | string | undefined = new URLSearchParams();
        const options: {
          headers: {
              'Content-Type': string;
          };
        } = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        if (
          (process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID != null) &&
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_OAUTH_TOKEN_URL
        ) {
          body.set('grant_type', 'refresh_token');
          body.set(
            'client_id',
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID,
          );
          body.set(
            'refresh_token',
            storageType(
              process.env.REACT_APP_STORAGE
                ? process.env.REACT_APP_STORAGE
                : 'local',
            ).getItem(`${process.env.REACT_APP_NAME ?? ''}-refresh-token`) ?? '',
          );
          const response: AxiosResponse = await axios.post(
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_OAUTH_TOKEN_URL,
            body.toString(),
            options,
          );
          const session: CognitoUserSession = new CognitoUserSession({
            AccessToken: new CognitoAccessToken({
              AccessToken: response.data.access_token,
            }),
            IdToken: new CognitoIdToken({
              IdToken: response.data.id_token,
            }),
          });

          if (session.isValid()) {
            const token : string = session.getIdToken().getJwtToken();
            const accessToken : string = session.getAccessToken().getJwtToken();
            storageType(
              process.env.REACT_APP_STORAGE
                ? process.env.REACT_APP_STORAGE
                : 'local',
            ).setItem(`${process.env.REACT_APP_NAME ?? ''}-token`, token);
            storageType(
              process.env.REACT_APP_STORAGE
                ? process.env.REACT_APP_STORAGE
                : 'local',
            ).setItem(
              `${process.env.REACT_APP_NAME ?? ''}-access-token`,
              accessToken,
            );

            return token;
          }

          return undefined;
        }
      } catch {
        return undefined;
      }

      return undefined;
    },
    async confirmNewPassword(user: SignInOutput, challenge: string): Promise<ConfirmSignInOutput | undefined> {
      try {
        const result: ConfirmSignInOutput = await confirmSignIn({
          challengeResponse: challenge,
        });
        this.setIsLoggedIn(true);

        return(result);
      } catch (err) {
        toast.error(t('user_generic_error'));
      }
    },
    async forgotPasswordRequest(
      username: string,
    ): Promise<ResetPasswordOutput> {
      return new Promise<ResetPasswordOutput>((
        resolve: (value: PromiseLike<ResetPasswordOutput> | ResetPasswordOutput) => void,
        reject: (reason?: any) => void,
      ) => {
        try {
          const result: Promise<ResetPasswordOutput> = resetPassword({ username });
          resolve(result);
        } catch (err) {
          reject(err);
        }
      });
    },
    async submitForgotPassword(
      confirmationCode: string,
      username: string,
      newPassword: string,
    ): Promise<void> {
      return new Promise<void>((
        resolve: (value: PromiseLike<void> | void) => void,
        reject: (reason?: any) => void,
      ) => {
        try {
          const result: Promise<void> = confirmResetPassword({
            username,
            newPassword,
            confirmationCode,
          });
          resolve(result);
        } catch (err) {
          reject(err);
        }
      });
    },
    async changeUserPassword(
      oldPassword: string,
      newPassword: string,
    ): Promise<void> {
      return new Promise<void>((
        resolve: (value: PromiseLike<void> | void) => void,
        reject: (reason?: any) => void,
      ) => {
        try {
          const result: Promise<void> = updatePassword({ oldPassword, newPassword });
          resolve(result);
        } catch (err) {
          reject(err);
        }
      });
    },
    async getCurrentUser(): Promise<AuthUser | null> {
      try {
        const user: AuthUser = await getCurrentUser();

        return user;
      } catch {
        toast.error(t('user_generic_error'));
      }

      return null;
    },
    async currentSession(): Promise<JWT | null> {
      return fetchAuthSession().then((value: AuthSession) => {
        const { idToken } = value.tokens ?? {};
        if (typeof idToken !== 'undefined') {
          return idToken;
        } else {
          return null;
        }
      }).catch(() => {
        throw new Error('No sessions found!');
      });
    },
    async getSignInUserSession(): Promise<FetchUserAttributesOutput | null> {
      return new Promise<FetchUserAttributesOutput | null>((
        resolve: (value: FetchUserAttributesOutput | PromiseLike<FetchUserAttributesOutput | null> | null) => void,
        reject: (reason?: any) => void,
      ) => {
        try {
          resolve(fetchUserAttributes());
        } catch (err) {
          reject(err);
        }
      },
      );
    },
    async oAuthAttributes(): Promise<any> {
      try {
        const options : AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${isTokenExpired(
              storageType(process.env.REACT_APP_STORAGE ?? 'local',
              ).getItem(`${process.env.REACT_APP_NAME ?? ''}-access-token`) ?? '',
            ) ? await this.getToken()
              : storageType(process.env.REACT_APP_STORAGE ?? 'local',
              ).getItem(`${process.env.REACT_APP_NAME ?? ''}-access-token`) ?? ''}`,
          },
        };

        if (process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_USER_INFO != null) {
          const response : AxiosResponse = await axios.get(
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_USER_INFO,
            options,
          );
          const attributes: UserAttributes = response.data;

          return attributes;
        }
      } catch {
        try {
          const options : AxiosRequestConfig = {
            headers: {
              Authorization: `Bearer ${
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                isTokenExpired(
                  storageType(
                    process.env.REACT_APP_STORAGE ??'local',
                  ).getItem(`${process.env.REACT_APP_NAME ?? ''}-access-token`),
                )
                  ? await this.getToken()
                  : storageType(
                    process.env.REACT_APP_STORAGE ?? 'local',
                  ).getItem(`${process.env.REACT_APP_NAME ?? ''}-access-token`)
              }`,
            },
          };
          if (process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_USER_INFO) {
            const response : AxiosResponse = await axios.get(
              process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_USER_INFO,
              options,
            );
            const attributes: UserAttributes = response.data;

            return attributes;
          }
        } catch {
          toast.error(t('user_generic_error'));
        }
      }

      return undefined;
    },
    async logout(): Promise<void> {
      const s: AuthSession = await fetchAuthSession();
      if (s.tokens != null) {
        await signOut({ global: true });
        storageType(process.env.REACT_APP_STORAGE ?? '').removeItem(
          `${process.env.REACT_APP_NAME ?? ''}-token`,
        );
        storageType(process.env.REACT_APP_STORAGE ?? '').removeItem(
          `${process.env.REACT_APP_NAME ?? ''}-logged-user`,
        );
        this.setIsLoggedIn(false);
        this.setUserAttributes(null);
      }
    },
    oAuthLogout(): void {
      if ((window as any).Cypress) {
        localStorage.removeItem('givenchy-quality-platform-webapp-token');
        localStorage.removeItem('givenchy-quality-platform-webapp-access-token');
        localStorage.removeItem('givenchy-quality-platform-webapp-refresh-token');
      } else {
        storageType(
          process.env.REACT_APP_STORAGE ?? 'local',
        ).removeItem(`${process.env.REACT_APP_NAME ?? ''}-token`);
        storageType(
          process.env.REACT_APP_STORAGE ?? 'local',
        ).removeItem(`${process.env.REACT_APP_NAME ?? ''}-access-token`);
        storageType(
          process.env.REACT_APP_STORAGE ?? 'local',
        ).removeItem(`${process.env.REACT_APP_NAME ?? ''}-refresh-token`);
        storageType(
          process.env.REACT_APP_STORAGE ?? 'local',
        ).removeItem('method');
      }
      this.setIsLoggedIn(false);
    },
    // async getAttributes(): Promise<any> {
    //   return (await get<any>('backoffice/admins/me')).data;
    // },
    setIsLoggedIn(state: boolean): void {
      this.isLoggedIn = state;
      setAuth(this);
    },
    getIsLoggedIn(): boolean {
      return auth.isLoggedIn;
    },
    setUserAttributes(user: any): void {
      this.userAttributes = user;
      setAuth(this);
    },
    getUserAttributes(): any {
      return auth.userAttributes;
    },
  };
};

export default AuthService;
