import type { Auth } from '../services/Auth/Auth';
import type Loading from '../services/LoadingSpinner/Loading';
import type { User } from '../services/User/User';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import UnAuthorized from '../components/unauthorized/UnAuthorized';
import Dashboard from '../pages/Dashboard/Dashboard';
import DownloadPdf from '../pages/DownloadPdf/DownloadPdf';
import Login from '../pages/Login/Login';
import ResetPassword from '../pages/Login/ResetPassword';
import Orders from '../pages/Orders/Orders';
import AuthProtectedRoute from '../services/Auth/AuthProtectedRoutes';
import useAuth from '../services/Auth/AuthService';
import LoadingSpinner from '../services/LoadingSpinner/LoadingSpinner';
import useLoadingSpinnerContext from '../services/LoadingSpinner/LoadingSpinnerService';
import useUser from '../services/User/UserService';

function AppRoutes(): JSX.Element {
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const auth: Auth = useAuth();
  const user: User = useUser();

  return (
    <BrowserRouter>
      <Routes>
        {/* ROOT PATH */}
        <Route
          path="/"
          element={
            <AuthProtectedRoute
              redirectTo={user.active_role != null && user.active_role.role === 'INSPECTOR' ? '/orders' : '/home'}
              returnChildren={!auth.getIsLoggedIn()}
            >
              <Navigate to="/login" replace />
            </AuthProtectedRoute>
          }
        />

        {/* LOGIN PATH */}
        <Route
          path="/login"
          element={
            <AuthProtectedRoute
              redirectTo={user.active_role != null && user.active_role.role === 'INSPECTOR' ? '/orders' : '/home'}
              returnChildren={!auth.getIsLoggedIn()}
            >
              <Login />
            </AuthProtectedRoute>
          }
        />

        {/* RESET PASSWORD PATH */}
        <Route
          path="/reset-password"
          element={
            <AuthProtectedRoute
              redirectTo={user.active_role != null && user.active_role.role === 'INSPECTOR' ? '/orders' : '/home'}
              returnChildren={!auth.getIsLoggedIn()}
            >
              <ResetPassword />
            </AuthProtectedRoute>
          }
        />
        <Route
          path="/download-pdf"
          element={
            <DownloadPdf />
          }
        />

        {/* MAIN PATHS */}
        <Route
          element={
            <AuthProtectedRoute
              redirectTo="/login"
              returnChildren={auth.getIsLoggedIn()}
            >
              <>
                {loadingSpinner.getCounter() > 0 ? <LoadingSpinner /> : null}
                <Layout />
              </>
            </AuthProtectedRoute>
          }
        >
          <Route
            path="/home"
            element={
              <AuthProtectedRoute
                redirectTo="/login"
                returnChildren={auth.getIsLoggedIn()}
              >
                {user.active_role != null && (user.active_role.role === 'SUPPLIER' || user.active_role.role === 'COORDINATOR') ? <Dashboard />: user.active_role != null && (user.active_role.role === 'INSPECTOR' || user.active_role.role === 'PRODUCTION')? <Orders /> : <UnAuthorized />}
              </AuthProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <AuthProtectedRoute
                redirectTo="/login"
                returnChildren={auth.getIsLoggedIn()}
              >
                <Orders />
              </AuthProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
