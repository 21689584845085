import type Loading from '../../services/LoadingSpinner/Loading';
import type { ColorMode } from '../../services/Theme/ThemeContext';
import type { User } from '../../services/User/User';
import type { ErrorRespose, OrderItemResponse, RoundResponse, TestingTypeWithSubtypes } from '../../utils/response';
import type { TabsActions } from '@mui/material';
import type { AxiosError, AxiosResponse } from 'axios';
import type { BaseSyntheticEvent, MutableRefObject, SyntheticEvent } from 'react';
import type { ControllerRenderProps, SubmitHandler } from 'react-hook-form';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Drawer, Fab, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import TableCalendar from '../../components/calendar/TableCalendar';
import ReportDialog from '../../components/dialogs/ReportDialog/ReportDialog';
import OrderItemList from '../../components/lists/OrderItemList';
import RoundList from '../../components/lists/RoundList';
import { get } from '../../services/Api/ApiFunctions';
import useLoadingSpinnerContext from '../../services/LoadingSpinner/LoadingSpinnerService';
import { useColorMode } from '../../services/Theme/ThemeContext';
import useUser from '../../services/User/UserService';
import { checkDownloadPdf } from '../../utils/checkDownloadPdf';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface SupplierSelect {
  id: number;
  name: string;
}

export interface InspectorSelect {
  id: number;
  name: string;
  surname: string;
}

interface FormTableValues {
  orderitemNumber: string;
  skuCode: string;
  colorCode: string;
  collectionCode: string;
  skuLine: string;
  supplierId: string;
}

interface FormCalendarValues {
  week: string;
  orderitemNumber: string;
  skuCode: string;
  colorCode: string;
  supplierId: string;
  inspectorId: string;
  allDepartments: boolean;
}

function CustomTabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <>
      {value === index && (
        <Box
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          sx={{ width:'100%', justifyContent: 'center', textAlign: 'center' }}
          {...other}
        >
          { children }
        </Box>
      )}
    </>
  );
}

function a11yProps(index: number): any {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Dashboard(): JSX.Element {
  const user: User = useUser();
  const theme: ColorMode = useColorMode();
  const firstUpdate: MutableRefObject<boolean> = useRef(true);
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const { t } = useTranslation();
  const containerRef: MutableRefObject<null> = useRef(null);
  const actionRef: MutableRefObject<TabsActions | null> = useRef<TabsActions | null>(null);
  const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
  const [isOpenReportDialog, setIsOpenReportDialog] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [suppliers, setSuppliers] = useState<SupplierSelect[]>([]);
  const [inspectors, setInspectors] = useState<InspectorSelect[]>([]);
  const [tabNumber, setTabNumber] = useState(0);
  const [orderItems, setOrderItems] = useState<OrderItemResponse | undefined>();
  const [blockedRounds, setBlockedRounds] = useState<RoundResponse | undefined>();
  const [editableRounds, setEditableRounds] = useState<RoundResponse | undefined>();
  const [selfTestingRounds, setSelfTestingRounds] = useState<RoundResponse | undefined>();
  const [testRequestsRounds, setTestRequestsRounds] = useState<RoundResponse | undefined>();
  const [types, setTypes] = useState<TestingTypeWithSubtypes[] | undefined>();

  const [orderItemPage, setOrderItemPage] = useState<number>(parseFloat((searchParams.get('page') ?? '0')));
  const [blockedRoundsPage, setBlockedRoundsPage] = useState<number>(parseFloat((searchParams.get('page') ?? '0')));
  const [editableRoundsPage, setEditableRoundsPage] = useState<number>(parseFloat((searchParams.get('page') ?? '0')));
  const [selfTestingRoundsPage, setSelfTestingRoundsPage] = useState<number>(parseFloat((searchParams.get('page') ?? '0')));
  const [testRequestsRoundsPage, setTestRequestsRoundsPage] = useState<number>(parseFloat((searchParams.get('page') ?? '0')));
  const { register, reset, control, handleSubmit, getValues, formState: { isValid } } = useForm<FormTableValues>({
    mode: 'all',
    defaultValues: {
      orderitemNumber: '',
      skuCode: '',
      colorCode: '',
      skuLine: '',
      collectionCode: '',
      supplierId: '',
    },
    values: {
      orderitemNumber: searchParams.get('orderitemNumber') ?? '',
      skuCode: searchParams.get('skuCode') ?? '',
      colorCode: searchParams.get('colorCode') ?? '',
      skuLine: searchParams.get('skuLine') ?? '',
      collectionCode: searchParams.get('collectionCode') ?? '',
      supplierId: searchParams.get('supplierId') ?? '',
    },
  });

  const {
    register: registerCalendar,
    reset: resetCalendar,
    control: controlCalendar,
    handleSubmit: handleSubmitCalendar,
    getValues: getCalendarValues,
    formState: { isValid: isValidCalendar },
  } = useForm<FormCalendarValues>({
    mode: 'all',
    defaultValues: {
      week: '',
      orderitemNumber: '',
      skuCode: '',
      colorCode: '',
      supplierId: '',
      inspectorId: '',
      allDepartments: true,
    },
    values: {
      week: searchParams.get('calendar_week') ?? '',
      orderitemNumber: searchParams.get('calendar_orderitemNumber') ?? '',
      skuCode: searchParams.get('calendar_skuCode') ?? '',
      colorCode: searchParams.get('calendar_colorCode') ?? '',
      supplierId: searchParams.get('calendar_supplierId') ?? '',
      inspectorId: searchParams.get('calendar_inspectorId') ?? '',
      allDepartments:Boolean(searchParams.get('calendar_allDepartments') ?? ''),
    },
  });

  const handleFilters = (status?: string): URLSearchParams => {
    const filters: URLSearchParams = new URLSearchParams();
    const FormTableValues: FormTableValues = getValues();

    switch (status) {
      case 'locked':
        filters.set('status', 'locked');
        filters.set('page', blockedRoundsPage.toString());
        filters.set('start', (10 * blockedRoundsPage).toString());
        break;
      case 'editable':
        filters.set('status', 'editable');
        filters.set('page', editableRoundsPage.toString());
        filters.set('start', (10 * editableRoundsPage).toString());
        break;
      case 'self':
        filters.set('status', 'self');
        filters.set('page', selfTestingRoundsPage.toString());
        filters.set('start', (10 * selfTestingRoundsPage).toString());
        break;
      case 'new':
        filters.set('status', 'new');
        filters.set('page', testRequestsRoundsPage.toString());
        filters.set('start', (10 * testRequestsRoundsPage).toString());
        break;
      case 'orderitems':
        filters.set('page', orderItemPage.toString());
        filters.set('start', (10 * orderItemPage).toString());
        filters.set('onlyUnfinishedOrderItems', 'true');
        break;
      default:
        break;
    }
    for(const key in FormTableValues) {
      if(FormTableValues[key as keyof typeof FormTableValues] !== '') {
        filters.set(key, FormTableValues[key as keyof typeof FormTableValues]);
      }else{
        filters.delete(key);
      }
    }

    return filters;
  };

  const handleFetchOrderItemsData = (): void => {
    const filters: URLSearchParams = handleFilters('orderitems');
    get<OrderItemResponse>('/orderitems', filters)
      .then((res: AxiosResponse<OrderItemResponse, any> | undefined): void => {
        if(res?.data !== undefined) {
          setOrderItems({ ...res.data });
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };
  const handleFetchBlockedRoundsData = (): void => {
    const filters: URLSearchParams = handleFilters('locked');
    get<RoundResponse>('/testing-rounds', filters)
      .then((res: AxiosResponse<RoundResponse, any> | undefined): void => {
        if(res?.data !== undefined) {
          setBlockedRounds({ ...res.data });
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };
  const handleFetchEditableRoundsData = (): void => {
    const filters: URLSearchParams = handleFilters('editable');
    get<RoundResponse>('/testing-rounds', filters)
      .then((res: AxiosResponse<RoundResponse, any> | undefined): void => {
        if(res?.data !== undefined) {
          setEditableRounds({ ...res.data });
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };
  const handleFetchSelfTestingRoundsData = (): void => {
    const filters: URLSearchParams = handleFilters('self');
    get<RoundResponse>('/testing-rounds', filters)
      .then((res: AxiosResponse<RoundResponse, any> | undefined): void => {
        if(res?.data !== undefined) {
          setSelfTestingRounds({ ...res.data });
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };
  const handleFetchTestRequestsRoundsData = (): void => {
    const filters: URLSearchParams = handleFilters('new');
    get<RoundResponse>('/testing-rounds', filters)
      .then((res: AxiosResponse<RoundResponse, any> | undefined): void => {
        if(res?.data !== undefined) {
          setTestRequestsRounds({ ...res.data });
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };
  const handleFetchTypesData = (): void => {
    get<TestingTypeWithSubtypes[]>('/testing-types')
      .then((res: AxiosResponse<TestingTypeWithSubtypes[], any> | undefined): void => {
        if (res?.data !== undefined) {
          setTypes(res.data);
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };

  // fix for Mui tab span resize opening sidebar
  useEffect(() => {
    const ro: ResizeObserver = new ResizeObserver(() => {
      if ((actionRef.current != null)) {
        actionRef.current.updateIndicator();
      }
    });

    // eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
    if (containerRef.current != null) {
      ro.observe(containerRef.current);
    }
  }, [containerRef.current, actionRef.current]);

  useEffect(() => {
    loadingSpinner.addRequest();
    get<SupplierSelect[]>('/suppliers')
      .then((res: AxiosResponse<SupplierSelect[], any> | undefined) => {
        if(res?.data !== undefined) {
          setSuppliers(res.data);
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
    get<InspectorSelect[]>('/inspectors')
      .then((res: AxiosResponse<InspectorSelect[], any> | undefined) => {
        if(res?.data !== undefined) {
          setInspectors(res.data);
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
    loadingSpinner.addResponse();
  },[user.activeRoleId]);

  const handleFetchLists = (): void => {
    if(user.active_role?.role === 'COORDINATOR') {
      handleFetchTestRequestsRoundsData();
      handleFetchBlockedRoundsData();
      handleFetchEditableRoundsData();
      handleFetchTypesData();
    }
    if(user.active_role?.role === 'SUPPLIER') {
      handleFetchOrderItemsData();
      handleFetchBlockedRoundsData();
      handleFetchEditableRoundsData();
      handleFetchSelfTestingRoundsData();
    }
  };

  const handleResetPages = (): void => {
    if(user.active_role?.role === 'COORDINATOR') {
      setTestRequestsRoundsPage(0);
      setBlockedRoundsPage(0);
      setEditableRoundsPage(0);
    }
    if(user.active_role?.role === 'SUPPLIER') {
      setOrderItemPage(0);
      setBlockedRoundsPage(0);
      setEditableRoundsPage(0);
      setSelfTestingRoundsPage(0);
    }
  };

  const handleResetFilters = (): void => {
    onReset();
    handleFetchLists();
    handleResetPages();
  };

  useEffect(() => {
    checkDownloadPdf();
  },[]);

  useEffect(() => {
    handleResetPages();
    if(!firstUpdate.current && !isOpenCalendar) {
      onReset();
      handleFetchLists();
    }
    firstUpdate.current = false;
    if(isOpenCalendar) {
      setIsOpenCalendar(false);
    }
    onResetCalendar();
    setTabNumber(0);
  }, [user.activeRoleId]);

  useEffect(() => {
    if(!isOpenCalendar) {
      if(user.active_role?.role === 'SUPPLIER') {
        loadingSpinner.addRequest();
        handleFetchOrderItemsData();
      }
      loadingSpinner.addResponse();
    }
  }, [orderItemPage, isOpenCalendar]);

  useEffect(() => {
    if(!isOpenCalendar) {
      loadingSpinner.addRequest();
      handleFetchBlockedRoundsData();
      loadingSpinner.addResponse();
    }
  }, [blockedRoundsPage, isOpenCalendar]);

  useEffect(() => {
    if(!isOpenCalendar) {
      loadingSpinner.addRequest();
      handleFetchEditableRoundsData();
      loadingSpinner.addResponse();
    }
  }, [editableRoundsPage, isOpenCalendar]);

  useEffect(() => {
    if(!isOpenCalendar) {
      loadingSpinner.addRequest();
      if(user.active_role?.role === 'SUPPLIER') {
        handleFetchSelfTestingRoundsData();
        loadingSpinner.addResponse();
      }
    }
  }, [selfTestingRoundsPage, isOpenCalendar]);

  useEffect(() => {
    if(!isOpenCalendar) {
      if(user.active_role?.role === 'COORDINATOR') {
        loadingSpinner.addRequest();
        handleFetchTestRequestsRoundsData();
        loadingSpinner.addResponse();
      }
    }
  }, [testRequestsRoundsPage, isOpenCalendar]);

  useEffect(() => {
    if(!isOpenCalendar) {
      if(user.active_role?.role === 'COORDINATOR') {
        loadingSpinner.addRequest();
        handleFetchTypesData();
        loadingSpinner.addResponse();
      }
    }
  }, [isOpenCalendar]);

  const handleChange = (event: SyntheticEvent, newTabNumber: number): void => {
    setTabNumber(newTabNumber);
  };

  const onSubmitFilter: SubmitHandler<FormTableValues> = (formData: FormTableValues, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    const previewSearchParams: URLSearchParams = searchParams;
    for(const key in formData) {
      if(formData[key as keyof typeof formData] !== '') {
        previewSearchParams.set(key, formData[key as keyof typeof formData]);
      }else{
        previewSearchParams.delete(key);
      }
      setSearchParams(previewSearchParams);
    }
    handleFetchLists();
    handleResetPages();
  };

  const onOpenCalendar = (): void => {
    const previewSearchParams: URLSearchParams = searchParams;
    if(user.active_role?.role === 'COORDINATOR') {
      previewSearchParams.set('calendar_allDepartments', 'true');
    }
    setSearchParams(previewSearchParams);
    setIsOpenCalendar(!isOpenCalendar);
    setIsOpenReportDialog(false);
  };

  const onCloseCalendar = (): void => {
    const previewSearchParams: URLSearchParams = searchParams;
    setIsOpenCalendar(false);
    previewSearchParams.delete('calendar_allDepartments');
    setSearchParams(previewSearchParams);
  };

  const onSubmitFilterCalendar: SubmitHandler<FormCalendarValues> = (formData: FormCalendarValues, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    const previewSearchParams: URLSearchParams = searchParams;
    for(const key in formData) {
      if(formData[key as keyof typeof formData] !== '') {
        previewSearchParams.set(`calendar_${key}`, formData[key as keyof typeof formData].toString());
      }else{
        previewSearchParams.delete(`calendar_${key}`);
      }
      setSearchParams(previewSearchParams);
    }
  };

  const onReset = (): void => {
    const formTableValues: FormTableValues = getValues();
    const previewSearchParams: URLSearchParams = searchParams;
    reset({
      orderitemNumber: '',
      skuCode: '',
      colorCode: '',
      collectionCode: '',
      skuLine: '',
      supplierId: '',
    });
    for(const key in formTableValues) {
      previewSearchParams.delete(key);
    }
    setSearchParams(previewSearchParams);
  };

  const onResetCalendar = (): void => {
    const formCalendarValues: FormCalendarValues = getCalendarValues();
    const previewSearchParams: URLSearchParams = searchParams;
    resetCalendar();
    for(const key in formCalendarValues) {
      previewSearchParams.delete(`calendar_${key}`);
    }
    if(isOpenCalendar && user.active_role?.role === 'COORDINATOR') {
      previewSearchParams.set('calendar_allDepartments', 'true');
      resetCalendar({ allDepartments: true });
    }
    setSearchParams(previewSearchParams);
  };

  return (
    <>
      <Accordion sx={{ boxShadow: 2, width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content">
          <Box sx={{ display:'flex', gap:1, alignItems:'center' }}>
            <FilterListIcon/>
            <Typography fontWeight='bold'>{t('filters_label')}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display:'flex', m:2 }}>
            <form>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Controller
                    render={({ field }: {field: ControllerRenderProps<FormTableValues, 'orderitemNumber'>}): JSX.Element =>
                      <TextField {...field} variant="outlined" placeholder={t('dashboard_orderitemcode_label')} label={t('dashboard_orderitemcode_label')} autoComplete="orderitemNumber" />}
                    name="orderitemNumber"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Controller
                    render={({ field }: {field: ControllerRenderProps<FormTableValues, 'skuCode'>}): JSX.Element =>
                      <TextField {...field} variant="outlined" placeholder={t('testinground_style_label')} label={t('testinground_style_label')} autoComplete="skuCode" />}
                    name="skuCode"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Controller
                    render={({ field }: {field: ControllerRenderProps<FormTableValues, 'colorCode'>}): JSX.Element =>
                      <TextField {...field} variant="outlined" placeholder={t('testinground_colorcode_label')} label={t('testinground_colorcode_label')} autoComplete="colorCode" />}
                    name="colorCode"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Controller
                    render={({ field }: {field: ControllerRenderProps<FormTableValues, 'skuLine'>}): JSX.Element =>
                      <TextField {...field} variant="outlined" placeholder={t('testinground_line_label')} label={t('testinground_line_label')} autoComplete="skuLine" />}
                    name="skuLine"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Controller
                    render={({ field }: {field: ControllerRenderProps<FormTableValues, 'collectionCode'>}): JSX.Element =>
                      <TextField {...field} variant="outlined" placeholder={t('dashboard_collectioncode_label')} label={t('dashboard_collectioncode_label')} autoComplete="collectionId" />}
                    name="collectionCode"
                    control={control}
                  />
                </Grid>
                {user.active_role?.role !== 'SUPPLIER' &&
                  <>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <FormControl>
                        <Controller
                          name="supplierId"
                          control={control}
                          defaultValue=''
                          render={({ field }: {field: ControllerRenderProps<FormTableValues, 'supplierId'>}): JSX.Element => (
                            <TextField
                              {...field}
                              select
                              variant="outlined"
                              label={t('addvisit_supplier_title')}
                              {...register('supplierId')}
                            >
                              <MenuItem key={-1}></MenuItem>
                              {suppliers.map((supplier: SupplierSelect) => {
                                return (
                                  <MenuItem value={supplier.id} key={supplier.id}>
                                    {`${supplier.name}`}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sx={{ ml: 'auto', alignContent:'center' }}>
                      <Button type="submit" sx={{ mr: 3 }} variant="contained" color='primary' disabled={!isValid} onClick={handleSubmit(onSubmitFilter)} >{t('filter_action')}</Button>
                      <Button type='reset' color='error' variant="contained" onClick={handleResetFilters}>{t('remove_filter_action')}</Button>
                    </Grid>
                  </>
                }
                {user.active_role?.role !== 'COORDINATOR' &&
              <Grid item sx={{ ml: 'auto', alignContent:'center' }}>
                <Button type="submit" sx={{ mr: 3 }} variant="contained" color='primary' disabled={!isValid} onClick={handleSubmit(onSubmitFilter)} >{t('filter_action')}</Button>
                <Button type='reset' color='error' variant="contained" onClick={handleResetFilters}>{t('remove_filter_action')}</Button>
              </Grid>
                }
              </Grid>
            </form>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ width: '100%', display:'flex', flexDirection:'column', gap:2, mb:6 }}>
        <Box ref={containerRef}>
          {user.active_role?.role === 'COORDINATOR' && (
            <Tabs centered action={actionRef} value={tabNumber} onChange={handleChange} >
              <Tab {...a11yProps(0)} sx={{ px: '40px' }} label={testRequestsRounds?.totalCount !== undefined ? <Badge badgeContent={testRequestsRounds.totalCount > 1000 ? '+1000' : testRequestsRounds.totalCount} color="primary"><Typography fontWeight='bold'>{t('dashboard_tab_testrequests_title')}</Typography></Badge> : <Typography>{t('dashboard_tab_testrequests_title')}</Typography>} />
              <Tab {...a11yProps(1)} sx={{ px: '40px' }} label={blockedRounds?.totalCount !== undefined ? <Badge badgeContent={blockedRounds.totalCount > 1000 ? '+1000' : blockedRounds.totalCount} color="primary"><Typography fontWeight='bold'>{t('dashboard_tab_blockedlots_title')}</Typography></Badge> : <Typography>{t('dashboard_tab_blockedlots_title')}</Typography>} />
              <Tab {...a11yProps(2)} sx={{ px: '40px' }} label={editableRounds?.totalCount !== undefined ? <Badge badgeContent={editableRounds.totalCount > 1000 ? '+1000' : editableRounds.totalCount} color="primary"><Typography fontWeight='bold'>{t('dashboard_tab_editableitems_title')}</Typography></Badge> : <Typography>{t('dashboard_tab_editableitems_title')}</Typography>} />
            </Tabs>
          )}
          {user.active_role?.role === 'SUPPLIER' && (
            <Tabs centered action={actionRef} value={tabNumber} onChange={handleChange} >
              <Tab {...a11yProps(3)} sx={{ px: '40px' }} label={orderItems?.totalCount !== undefined ? <Badge badgeContent={orderItems.totalCount > 1000 ? '+1000' : orderItems.totalCount} color="primary"><Typography fontWeight='bold'>{t('dashboard_tab_orderitems_title')}</Typography></Badge>: <Typography>{t('dashboard_tab_orderitems_title')}</Typography>} />
              <Tab {...a11yProps(1)} sx={{ px: '40px' }} label={blockedRounds?.totalCount !== undefined ? <Badge badgeContent={blockedRounds.totalCount > 1000 ? '+1000' : blockedRounds.totalCount} color="primary"><Typography fontWeight='bold'>{t('dashboard_tab_blockedlots_title')}</Typography></Badge> : <Typography>{t('dashboard_tab_blockedlots_title')}</Typography>} />
              <Tab {...a11yProps(2)} sx={{ px: '40px' }} label={editableRounds?.totalCount !== undefined ? <Badge badgeContent={editableRounds.totalCount > 1000 ? '+1000' : editableRounds.totalCount} color="primary"><Typography fontWeight='bold'>{t('dashboard_tab_editableitems_title')}</Typography></Badge> : <Typography>{t('dashboard_tab_editableitems_title')}</Typography>} />
              <Tab {...a11yProps(4)} sx={{ px: '40px' }} label={selfTestingRounds?.totalCount !== undefined ? <Badge badgeContent={selfTestingRounds.totalCount > 1000 ? '+1000' : selfTestingRounds.totalCount} color="primary"><Typography fontWeight='bold'>{t('dashboard_tab_selftesting_title')}</Typography></Badge> : <Typography>{t('dashboard_tab_selftesting_title')}</Typography>} />
            </Tabs>)}
        </Box>
        {user.active_role?.role === 'COORDINATOR' && (
          <>
            <CustomTabPanel value={tabNumber} index={0}>
              <RoundList
                type='new'
                emptyMessageCode='dashboard_testrequests_empty_label'
                roundListResponse={testRequestsRounds}
                pageNumber={testRequestsRoundsPage}
                typesList={types}
                inspectorsList={inspectors}
                setPageNumber={setTestRequestsRoundsPage}
                fetchTable={handleFetchTestRequestsRoundsData}
                fetchTabs={handleFetchLists} />
            </CustomTabPanel>
            <CustomTabPanel value={tabNumber} index={1}>
              <RoundList
                type='locked'
                emptyMessageCode='dashboard_blockedlots_empty_label'
                roundListResponse={blockedRounds}
                pageNumber={blockedRoundsPage}
                typesList={types}
                inspectorsList={inspectors}
                setPageNumber={setBlockedRoundsPage}
                fetchTable={handleFetchBlockedRoundsData}
                fetchTabs={handleFetchLists} />
            </CustomTabPanel>
            <CustomTabPanel value={tabNumber} index={2}>
              <RoundList
                type='editable'
                emptyMessageCode='dashboard_editableitems_empty_label'
                roundListResponse={editableRounds}
                pageNumber={editableRoundsPage}
                typesList={types}
                inspectorsList={inspectors}
                setPageNumber={setEditableRoundsPage}
                fetchTable={handleFetchEditableRoundsData}
                fetchTabs={handleFetchLists} />
            </CustomTabPanel>
          </>
        )}
        {user.active_role?.role === 'SUPPLIER' && (
          <>
            <CustomTabPanel value={tabNumber} index={0}>
              <OrderItemList
                orderItemListResponse={orderItems}
                pageNumber={orderItemPage}
                setPageNumber={setOrderItemPage}
                fetchTable={handleFetchLists} />
            </CustomTabPanel>
            <CustomTabPanel value={tabNumber} index={1}>
              <RoundList
                type='locked'
                emptyMessageCode='dashboard_blockedlots_empty_label'
                roundListResponse={blockedRounds}
                pageNumber={blockedRoundsPage}
                typesList={types}
                inspectorsList={inspectors}
                setPageNumber={setBlockedRoundsPage}
                fetchTable={handleFetchBlockedRoundsData}
                fetchTabs={handleFetchLists} />
            </CustomTabPanel>
            <CustomTabPanel value={tabNumber} index={2}>
              <RoundList
                type='editable'
                emptyMessageCode='dashboard_editableitems_empty_label'
                roundListResponse={editableRounds}
                pageNumber={editableRoundsPage}
                typesList={types}
                inspectorsList={inspectors}
                setPageNumber={setEditableRoundsPage}
                fetchTable={handleFetchEditableRoundsData}
                fetchTabs={handleFetchLists} />
            </CustomTabPanel>
            <CustomTabPanel value={tabNumber} index={3}>
              <RoundList
                type='self'
                emptyMessageCode='dashboard_selftesting_empty_label'
                roundListResponse={selfTestingRounds}
                pageNumber={selfTestingRoundsPage}
                typesList={types}
                inspectorsList={inspectors}
                setPageNumber={setSelfTestingRoundsPage}
                fetchTable={handleFetchSelfTestingRoundsData}
                fetchTabs={handleFetchLists} />
            </CustomTabPanel>
          </>
        )}
      </Box>

      <Drawer
        anchor='right'
        open={isOpenCalendar}
        onClose={onCloseCalendar}
        sx={{ zIndex:'1202' }}
        PaperProps={{
          sx: { width: '80vw', height: '100vh', display: 'flex', flexDirection: 'column' },
        }}
      >
        <Box sx={{ display:'flex', gap:1, m:2 }}>
          {user.active_role?.role === 'COORDINATOR' &&
          <Box sx={{ display:'flex', alignItems:'center', height:'48px' }}>
            <IconButton aria-label="email" onClick={(): void => setIsOpenReportDialog(true)}>
              <EmailIcon sx={{
                color: 'inherit',
                '&:hover': {
                  color: '#0959AA',
                },
              }}/>
            </IconButton>
          </Box>
          }
          <Accordion sx={{ boxShadow: 2, width:'100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content">
              <Box sx={{ display:'flex', gap:1, alignItems:'center' }}>
                <FilterListIcon/>
                <Typography fontWeight='bold'>{t('filters_label')}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display:'flex', m:2 }}>
                <form>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <Controller
                        render={({ field }: {field: ControllerRenderProps<FormCalendarValues, 'week'>}): JSX.Element =>
                          <TextField
                            {...field}
                            {...registerCalendar('week')}
                            type='week'
                            InputProps={{ sx:{
                              colorScheme: theme.mode === 'light' ? 'light' : 'dark',
                            } }}
                            sx={{ width: '100%' }}
                            variant="outlined"
                          />}
                        name="week"
                        control={controlCalendar}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <Controller
                        render={({ field }: {field: ControllerRenderProps<FormCalendarValues, 'orderitemNumber'>}): JSX.Element =>
                          <TextField {...field} variant="outlined" placeholder={t('dashboard_orderitemcode_label')} label={t('dashboard_orderitemcode_label')} autoComplete="orderitemNumber" />}
                        name="orderitemNumber"
                        control={controlCalendar}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <Controller
                        render={({ field }: {field: ControllerRenderProps<FormCalendarValues, 'skuCode'>}): JSX.Element =>
                          <TextField {...field} variant="outlined" placeholder={t('testinground_style_label')} label={t('testinground_style_label')} autoComplete="skuCode" />}
                        name="skuCode"
                        control={controlCalendar}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <Controller
                        render={({ field }: {field: ControllerRenderProps<FormCalendarValues, 'colorCode'>}): JSX.Element =>
                          <TextField {...field} variant="outlined" placeholder={t('testinground_colorcode_label')} label={t('testinground_colorcode_label')} autoComplete="colorCode" />}
                        name="colorCode"
                        control={controlCalendar}
                      />
                    </Grid>
                    {user.active_role?.role !== 'SUPPLIER' &&
                  <>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <FormControl>
                        <Controller
                          name="supplierId"
                          control={controlCalendar}
                          defaultValue=''
                          render={({ field }: {field: ControllerRenderProps<FormCalendarValues, 'supplierId'>}): JSX.Element => (
                            <TextField
                              {...field}
                              select
                              variant="outlined"
                              label={t('addvisit_supplier_title')}
                              {...registerCalendar('supplierId')}
                            >
                              <MenuItem key={-1}></MenuItem>
                              {suppliers.map((supplier: SupplierSelect) => {
                                return (
                                  <MenuItem value={supplier.id} key={supplier.id}>
                                    {`${supplier.name}`}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <FormControl>
                        <Controller
                          name="inspectorId"
                          control={controlCalendar}
                          defaultValue=''
                          render={({ field }: {field: ControllerRenderProps<FormCalendarValues, 'inspectorId'>}): JSX.Element => (
                            <TextField
                              {...field}
                              select
                              sx={{ width: '100%' }}
                              variant="outlined"
                              label={t('dashboard_inspector_select_label')}
                              {...registerCalendar('inspectorId')}
                            >
                              <MenuItem key={-1}></MenuItem>
                              {inspectors.map((inspector: InspectorSelect) => {
                                return (
                                  <MenuItem value={inspector.id} key={inspector.id}>
                                    {`${inspector.name} ${inspector.surname}`}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} sx={{ display:'flex', alignContent:'center' }}>
                      <Controller
                        render={({ field }: {field: ControllerRenderProps<FormCalendarValues, 'allDepartments'>}): JSX.Element =>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                {...registerCalendar('allDepartments')}
                                value={Boolean(field.value)}
                                checked={Boolean(field.value)}
                              />}
                            label={t('alldepartments_label')} />
                        }
                        name="allDepartments"
                        control={controlCalendar}
                      />
                    </Grid>
                    <Grid item sx={{ ml: 'auto', alignContent:'center' }}>
                      <Button type="submit" sx={{ mr: 3 }} variant="contained" color='primary' disabled={!isValidCalendar} onClick={handleSubmitCalendar(onSubmitFilterCalendar)}>{t('filter_action')}</Button>
                      <Button type='reset' variant="contained" color='error' onClick={onResetCalendar}>{t('remove_filter_action')}</Button>
                    </Grid>
                  </>
                    }
                    {
                      user.active_role?.role !== 'COORDINATOR' &&
                    <Grid item sx={{ ml: 'auto', alignContent:'center' }}>
                      <Button type="submit" sx={{ mr: 3 }} variant="contained" color='primary' disabled={!isValidCalendar} onClick={handleSubmitCalendar(onSubmitFilterCalendar)}>{t('filter_action')}</Button>
                      <Button type='reset' variant="contained" color='error' onClick={onResetCalendar}>{t('remove_filter_action')}</Button>
                    </Grid>
                    }
                  </Grid>
                </form>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <TableCalendar isOpenCalendar={isOpenCalendar} />
        <ReportDialog open={isOpenReportDialog}
          onClose={(): void => setIsOpenReportDialog(false)} />
      </Drawer>
      <Fab
        sx={{ bottom: 20, right: 40, position: 'fixed' }}
        onClick={onOpenCalendar}
      >
        <CalendarMonthIcon />
      </Fab>
    </>
  );
}

export default Dashboard;
