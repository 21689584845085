import './App.scss';

import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RequestInterceptor from '../services/Api/RequestInterceptor';
import ResponseInterceptor from '../services/Api/ResponseInterceptor';
import LoadingSpinner from '../services/LoadingSpinner/LoadingSpinner';
import { ThemeContextProvider } from '../services/Theme/ThemeContext';
import { UserProvider } from '../services/User/UserServiceProvider';
import { getLanguage } from '../utils/helpers';

import AppRoutes from './AppRoutes';
import('dayjs/locale/it');

dayjs.extend(updateLocale);
dayjs.updateLocale(getLanguage(), {
  weekStart: 1,
});

function App(): JSX.Element {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ThemeContextProvider>
        <RequestInterceptor />
        <ResponseInterceptor />
        <ToastContainer theme="colored" autoClose={3000} />
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getLanguage()}>
          <UserProvider>
            <AppRoutes />
          </UserProvider>
        </LocalizationProvider>
      </ThemeContextProvider>
    </Suspense>
  );
}

export default App;
